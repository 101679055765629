import { Box } from "@mui/material";

export default function Privacy() {
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 270px)",
        width: "100%",
        background: "#EDE6DC",
      }}
    >
      <Box
        maxWidth={1324}
        width={"100%"}
        sx={{ margin: "auto", width: "100%" }}
      >
        <Box py={2} px={{ xs: "20px", md: 0 }}>
          <h1>MUSE Privacy Policy</h1>
          <p>This policy took effect on April 24, 2024.</p>
          <p>
            At MUSE, we care deeply about privacy. We strive to be transparent
            about our privacy practices, including how we treat your personal
            information.
          </p>
          <p>
            This policy explains our privacy practices for jointhemuse.com
            (which we’ll refer to as the “Site”), MUSE App and other services
            provided by MUSE &amp; Partners Pty Ltd (ACN 653 255 693) (we’ll
            refer to as “MUSE,” or as “we,” “us,” and “our”). We’ll use the term
            “Services” to refer collectively to the Site and the App, endeavor
            at all times to comply with the Privacy Act 1988 (Cth) and the
            Australia Privacy Principles which together will be referred to in
            this policy as “”Australian Privacy Law”.
          </p>
          <p>
            This policy applies to our registered buyers (“Buyers”) and verified
            sellers (“Sellers”) (together, our “Users”), and anyone else who
            visits our Site, App or uses our Services (together with our users,
            “you”&nbsp;and&nbsp;“your”).
          </p>
          <p>
            This policy does not apply to the practices of third parties
            (including Sellers, Pattern by MUSE users) who you may choose to
            share information with when you use the Services. Please note that
            the collection and use of your information by these third parties is
            subject to their own privacy policies and terms. You should
            understand the privacy and security practices of any third party
            before you share information with them.
          </p>
          <p>
            It is important that you understand how we use your information. You
            should read this policy in full, but some key points and references
            to help you navigate the policy are:
          </p>
          <p>
            – Our mission at MUSE is to keep human connection at the heart of
            commerce. We connect Buyers with Sellers that offer investment-grade
            luxury goods supplies. By accepting our Terms of Use and this
            Privacy Policy, or by using any of our Services, you confirm that
            you have read and understand this policy, including how and why we
            use your information.
          </p>
          <p>
            – We collect and use your personal information to provide and
            improve our Services, to protect the security and integrity of our
            Services and to comply with our legal obligations. To learn more
            about what information we collect and how we use it, see Section 1
            and Section 2 below.
          </p>
          <p>
            – We share your information with other MUSE group companies, trusted
            partners and service providers to provide our Services, for security
            purposes, to understand how you use and interact with our Services
            and to meet our legal obligations. To learn more, see Section 3
          </p>
          <p>
            – If you have any questions about this Privacy Policy, or wish to
            exercise your rights, see Section 11 for further information about
            how to contact us
          </p>
          <h2>1. What Information We Collect and Receive</h2>
          <p>
            In the course of providing our Services, we collect and receive
            information that directly or indirectly relates to you (which we
            call your “personal information”) in different ways and from
            different sources, which we have set out below. The capitalized
            terms in this Section 1are definitions that we use throughout this
            Policy, so please refer back to this explanation as necessary.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Information You Provide to Us
            </strong>
          </p>
          <p>
            You provide certain information to MUSE directly when you sign up
            for a MUSE account or otherwise use the Services.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Buyer Account Information
            </strong>
          </p>
          <p>
            We collect certain information from you when you set up a MUSE Buyer
            account and use the Services.
          </p>
          <p>– Buyer Details: Your name, email address, and phone number</p>
          <p>
            – Buyer Payment Information: Your billing address and payment
            information (e.g., credit or debit card details)
          </p>
          <p>
            – Delivery Information: Your delivery address, contact name, and
            phone number
          </p>
          <p>
            – Regional Information: Your country, preferred language, and
            currency
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Seller Account Information
            </strong>
          </p>
          <p>
            We collect certain information from you when you set up a MUSE
            Seller account and use the Services.
          </p>
          <p>
            – Seller Details: Company Name, Registration No, email address,
            country of residence, Address, Type of business, legal structure,
            owner’s name, phone number, date of birth, taxpayer address, and
            MUSE user name
          </p>
          <p>
            – Seller Payment Information: Your bank country, bank account
            details, billing address and other payment information (e.g., credit
            or debit card details)
          </p>
          <p>
            – Account Preferences: Your preferred language, country, and
            currency
          </p>
          <p>
            – Business/Tax Information: Your tax and government-issued
            identification numbers
          </p>
          <p>
            – Regional Information: Your country, preferred language, and
            currency
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Buyer Public Profile Information
            </strong>
          </p>
          <p>
            As a Buyer on MUSE, you can choose to provide your name and other
            personal information in connection with your account and activity.
            Other people will be able to see your profile information. You can
            edit, remove or limit this information through your account
            settings.
          </p>
          <p>– Name</p>
          <p>– Birthday</p>
          <p>– Gender</p>
          <p>– Location</p>
          <p>– Profile photo</p>
          <p>– Your bio, story and story headline</p>
          <p>– Your favorite items and favorite stores</p>
          <p>
            – Linked third-party accounts (e.g., social media, “sign-in with”)
          </p>
          <p>– Product reviews</p>
          <p>
            – Registry information (e.g., name, partner name, wedding or special
            event date, shipping information)
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Seller Public Profile Information
            </strong>
          </p>
          <p>
            As a Seller on MUSE, you must provide certain information for your
            shop. You can also choose to share other personal information (such
            as birthday, gender or location) in connection with your account and
            activity. Other people will be able to see your profile information.
            You can edit, remove, or limit this information through your account
            settings.
          </p>
          <p>– Account name</p>
          <p>– Company name</p>
          <p>– Birthday</p>
          <p>– Gender</p>
          <p>– Location and/or business address (if applicable)</p>
          <p>– Profile photo</p>
          <p>– Your bio, story, story headline, shop photos, and shop links</p>
          <p>– Vacation mode announcements</p>
          <p>– Linked third-party accounts (e.g., social, “sign-in with”)</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Transaction Information
            </strong>
          </p>
          <p>
            When you buy or sell goods on MUSE, we collect information about the
            order as part of your transaction history.
          </p>
          <p>
            – Information about the transactions made on our Services, such as
            the shop name, items purchased, Buyer’s name, purchase price, and
            date of the transaction
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Photographs and Videos
            </strong>
          </p>
          <p>
            You may enable the Apps to access your mobile device’s camera to
            upload photographs and videos to MUSE, or you can upload photos and
            videos directly through our Site.
          </p>
          <p>
            – Photos and videos you upload to your profile, our forums, as part
            of product reviews or through MUSE’s other products and features
            (e.g., search)
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Forums/Community Information
            </strong>
          </p>
          <p>
            We offer several features that allow users to connect and
            communicate in public or semi-public spaces, such as Forums and
            Teams, which are a part of the MUSE Community space.
          </p>
          <p>– Content you post in our community spaces</p>
          <p>– Any additional information you choose to provide</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Messages Information
            </strong>
          </p>
          <p>
            You can contact another Member using MUSE Messages for a variety of
            reasons, such as customer support, personalizing products, or
            product and order questions.
          </p>
          <p>
            – Message Content: Content you share with, or receive from, other
            Users through our Messages function
          </p>
          <p>
            – Message Metadata: Metadata about content (such as the location
            where a photo was taken) and Messages (such as the date and time of
            the message)
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Customer Support Information
            </strong>
          </p>
          <p>
            We collect certain information when you engage with our customer
            support teams.
          </p>
          <p>– Your communications with our support team</p>
          <p>
            – Any additional information you provide, including transaction
            information (e.g., order number and transaction ID), and/or device
            and technical information (e.g., IP address for troubleshooting)
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Service Usage Information
            </strong>
          </p>
          <p>
            We collect certain information about your use of the Services, such
            as how often you use the App, how you interact with the Services,
            and actions you take when interacting with the Site and App, in
            order to help us ensure the Services are functioning correctly,
            tailor your experience, and improve our offerings. We may collect
            this information from a subset of users when testing new products or
            features.
          </p>
          <p>
            – Usage Information: Information about your interaction with our
            Site and App, including how often you use the Site and/or App, what
            happens within the Site and/or App, what listings you view and
            interact with, or other actions you take (such as clicking on
            particular parts of the Site or App)
          </p>
          <p>
            – Diagnostic and Troubleshooting Information: Information about how
            our Services are performing when you use them, like service-related
            diagnostic and performance information, including timestamps, crash
            data, website performance logs and error messages or reports
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              General Location Information
            </strong>
          </p>
          <p>
            We use your general location information for things like tailoring
            our Services to your location and to estimate shipping costs. See
            Section 2 for further details on how we use this information.
          </p>
          <p>
            – IP addresses and other information like your zip or postal code,
            to estimate your general location (e.g., country, state, or city)
          </p>
          <p>
            – The general location you set in your regional preferences on the
            Site or Apps
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Precise Location Information
            </strong>
          </p>
          <p>
            We use your precise geo-location where you have given us permission
            to do so through your device-based settings. See Section 2 for
            further detail.
          </p>
          <p>– Your precise geo-location collected from your device</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Information We Receive from Third Parties
            </strong>
          </p>
          <p>We receive certain information about you from third parties.</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Information from our Payment Processors
            </strong>
          </p>
          <p>
            We receive certain information about Buyers from our third-party
            payment processors, such as Stripe, who help us to process
            transactions, provide our Services and prevent fraudulent or illegal
            activity.
          </p>
          <p>– Your first and last name</p>
          <p>– Your billing address</p>
          <p>– Your email address</p>
          <p>– Your phone number</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Information from Advertising and Marketing Partners
            </strong>
          </p>
          <p>
            We receive certain information about you from our advertising and
            marketing partners, including in some instances what marketing
            content you viewed or clicked the actions you take on our Sites and
            Apps. This information helps us determine how our marketing
            campaigns and other customer interactions may contribute to sales,
            conversions, or other goals.
          </p>
          <p>
            – We may receive from our third-party partners (or may collect
            directly), for example, attribution information via cookies and UTM
            tags in URLs to help us determine the source of the traffic to MUSE
            or track the performance of a specific campaign
          </p>
          <p>
            – We may also receive responses to marketing emails and
            advertisements, responses to offers, and audience information from
            partners who you have given consent to share that information with
            us
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Analytics Information
            </strong>
          </p>
          <p>
            We use data analytics software to ensure Site and App functionality
            and to improve the Services.
          </p>
          <p>
            – We use third-party software to record information such as how
            often you use the Site and/or Apps, what happens within the Apps or
            on our Site (e.g., how you interact with our Services), aggregated
            usage, performance data, app errors and debugging information, and,
            in the case of App, where the App were downloaded from.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Third-Party Application Information
            </strong>
          </p>
          <p>
            We receive certain information when you engage with third-party
            applications or platform integrations on other sites and apps.
          </p>
          <p>
            – When you link our Services with third-party applications or
            integrations (e.g., linked third-party accounts), we receive your
            contact information, including your name and email address, and any
            additional information you authorize the third party to share with
            us (for example, social media accounts and/or sign-in functionality)
          </p>
          <h2>2. How We Use Your Information and Our Legal Basis</h2>
          <p>
            When you access or use the Services or interact with our Sites
            and/or Apps, we collect, use, share, and otherwise process your
            personal information for the purposes described in this policy. To
            the extent required by applicable law, we rely on a number of legal
            bases to use your information in these ways. If you reside outside
            the European Economic Area (“EEA”), Switzerland or the United
            Kingdom (“UK”) (together referred to as the “European Region”), the
            legal bases on which we rely may differ from those listed below.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Why and How We Use Your Information
            </strong>
          </p>
          <p>Information Categories Used</p>
          <p>Where necessary to perform our contract with you</p>
          <p>
            We use certain information that is necessary for us to provide you
            with the Services and perform our Terms of Use or other relevant
            contract(s) with you. We will need to close your account if we
            cannot process your personal information for such purposes.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To create and maintain your MUSE account and provide our Services
            </strong>
          </p>
          <p>
            We collect certain information in order to set up your MUSE account
            and to allow you to interact with the MUSE Community.
          </p>
          <p>– Buyer Account Information</p>
          <p>– Seller Account Information</p>
          <p>– Buyer Public Profile Information</p>
          <p>– Seller Public Profile Information</p>
          <p>– Browser and Device Information</p>
          <p>– Forums/Community Information</p>
          <p>– Third-Party Application Information (where relevant)</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To facilitate buying and selling on MUSE
            </strong>
          </p>
          <p>
            We use certain information to facilitate buying and selling on MUSE.
            This can also involve us sharing your information with some of our
            third-party partners – such as our payment partners – to enable us
            to provide the Services to you pursuant to our Terms of Use. Such
            partners will process your personal information in accordance with
            their own privacy policies. For more information, please see Section
            3.
          </p>
          <p>– Buyer Account Information</p>
          <p>– Seller Account Information</p>
          <p>– General Location Information</p>
          <p>– Information from our Payment Processors</p>
          <p>– Transaction Information</p>
          <p>
            – Photographs and Videos, including where you use certain features
            to search for products
          </p>
          <p>– Customer Support Information</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To provide our MUSE Payments service
            </strong>
          </p>
          <p>
            As a MUSE Seller, you may use our payment service, MUSE Payments, to
            accept various forms of payments on the Site, Apps, and other
            Services. You will need to provide certain information to set up an
            account.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To send Service communications
            </strong>
          </p>
          <p>
            We use certain information to send you administrative or
            account-related communications about our Services, including to
            verify your email address, welcome you to the Services, confirm a
            transaction, or notify you about an order, service availability,
            changes to the Services, or updates to this Policy, our Terms of
            Use, or other policies. You may not opt out of receiving service
            communications, such as emails or mobile notifications sent for
            legal, security, or other transactional purposes.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To provide customer support
            </strong>
          </p>
          <p>
            We use your information to respond to requests for support (via the
            App, Site, or email) and to respond to customer care and other
            inquiries, including providing telephone-based customer support to
            Users (who provide their telephone numbers).
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Where necessary to comply with our legal obligations
            </strong>
          </p>
          <p>
            Our Services are subject to laws and regulations that require us to
            collect, use, and store your personal information in certain ways.
            Where applicable, if you do not provide the personal information
            required by law, we will have to close your account.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To comply with local laws on selling goods
            </strong>
          </p>
          <p>
            For example, in certain countries, we are required to display
            certain information about our Sellers on MUSE.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To verify your identity
            </strong>
          </p>
          <p>
            To become a MUSE Seller, we need to verify your identity to comply
            with our obligations under a variety of laws, such as the Digital
            Services Act and anti-money laundering laws.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To comply with legal and regulatory obligations
            </strong>
          </p>
          <p>
            MUSE may also use, preserve, or release your personal information to
            a third party to comply with our legal and regulatory obligations,
            including in response to lawful requests by public authorities
            (including tax authorities) and to meet legitimate national security
            or law enforcement requirements.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Where necessary for our legitimate interests
            </strong>
          </p>
          <p>
            We rely on our legitimate interests or those of third parties (like
            other Buyers and Sellers, and in some cases, the general public)
            where they are not outweighed by your rights and freedoms. In the
            EEA and UK, you have the right to object to, and seek the
            restriction of, this processing. To learn more, see Section 7.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To understand how you use our Services and improve your experience
              on MUSE
            </strong>
          </p>
          <p>
            We use certain information to improve our Services, including to
            ensure the Services are functioning correctly, to help Sellers find
            the best ways to market and sell their products on MUSE, to identify
            and support high-performing Sellers, and for general research and
            aggregate reporting.
          </p>
          <p>
            It is in our legitimate interests to understand how our Services are
            being used, and to explore and unlock ways to develop and grow our
            business. It is in our legitimate interests, and those of our Users,
            to improve the efficiency of our Services, troubleshoot errors with
            our Services, increase interest in our Services, and obtain insights
            into usage patterns of our Services.
          </p>
          <p>
            It is in our legitimate interests, and those of our Sellers, to help
            Sellers reach the most appropriate audience for their goods.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To facilitate Seller campaigns, surveys and petitions
            </strong>
          </p>
          <p>
            Sellers have the ability to sign onto campaigns, surveys, or
            petitions for causes they care about or to donate to these causes.
            To facilitate this, we need to collect and use certain information
            about our Users.
          </p>
          <p>
            It is in our legitimate interests and our Users’ interests to
            provide these services and to conduct surveys to help us in
            improving our Services.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To keep our Services safe and secure
            </strong>
          </p>
          <p>
            We collect and store your information and, in some cases, apply
            automated processing and manual (i.e., human) review to maintain the
            security and integrity of our Services. This involves taking steps
            to detect, investigate and otherwise protect our community against
            spam, abuse, harassment, intellectual property infringement, crime,
            suspected fraud, harm, suspected violations of MUSE’s Terms of Use
            and safety and security risks of all kinds.
          </p>
          <p>
            It is in our legitimate interests, and those of our Users, to ensure
            the security of our Services, to verify accounts and activity, to
            combat harmful or inappropriate conduct or content and to detect,
            prevent and address spam.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To protect our legal rights
            </strong>
          </p>
          <p>
            MUSE may also retain, preserve or review your personal information
            to protect, establish, or exercise our legal rights or defend
            against impending or asserted legal claims, including to collect a
            debt or address a violation of our Terms of Use and policies.
          </p>
          <p>
            For example, in accordance with our existing case resolution
            process, we may use information you provide to resolve disputes with
            MUSE or other Users.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To share information with law enforcement and respond to legal
              requests
            </strong>
          </p>
          <p>
            We may share your information with a third party, including law
            enforcement authorities, where not compelled by law when we believe
            in good faith that such use and/or disclosure is reasonably
            necessary to detect, prevent or prosecute illegal activity or to
            prevent imminent death or imminent bodily harm.
          </p>
          <p>
            The categories of information that we access, preserve, use and
            share for these purposes will depend on the specific circumstances.
          </p>
          <p>
            To learn more about how and why MUSE shares information in these
            circumstances, see Section 3 and review our Requests for Information
            Policy.
          </p>
          <p>
            It is in our legitimate interests and the interests of the general
            public to share information with third parties, including as part of
            investigations or regulatory inquiries, to detect, investigate and
            prosecute illegal activity or to prevent death or imminent bodily
            harm.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              In the event of a business reorganization
            </strong>
          </p>
          <p>
            In some cases, MUSE may choose to reorganize its business. In these
            types of transactions (such as a sale, merger, liquidation,
            receivership, reorganization, or transfer of all or substantially
            all of MUSE’s assets), Member information is typically one of the
            business assets that is transferred.
          </p>
          <p>
            Such transactions may be necessary and in our legitimate interests,
            particularly our interest in making decisions that enable our
            business to develop over the long term.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Where you provide your consent
            </strong>
          </p>
          <p>
            When we use your information based on your consent, you have the
            right to withdraw your consent at any time on a go-forward basis
            (meaning our prior use of your data will not be affected). You may
            change your device-based or in-app settings anytime. Learn More.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              To personalize your experience on the Site and App
            </strong>
          </p>
          <p>
            For users in the European Region, we rely on your consent to
            personalize your experience on the Sites and Apps to help you search
            for and discover relevant items.
          </p>
          <p>
            To collect your information through the device-based settings that
            you have enabled
          </p>
          <p>We rely on your consent to:</p>
          <p>
            – collect your device-based Precise Location Information for: (a)
            local content, to personalize and tailor the Services, including
            search results and recommendations; and (b) delivery, and shipping
            services;
          </p>
          <p>
            – access your camera and/or photo gallery if you choose to share
            photos or media on our Services.
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Data used for tasks carried out in the public interest
            </strong>
          </p>
          <p>
            To preserve, review and share information to perform a task in the
            public interest
          </p>
          <p>
            In limited, fact-specific circumstances, we will access, preserve
            and share your information with regulators, law enforcement or
            others where necessary to perform a task in the public interest. For
            example, we might share information with others for the purposes of
            combating unlawful or harmful behavior where it is in the public
            interest to do so and where the public interest is laid down by
            applicable law (including EU or Member State law, where required).
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Data use to protect your or others’ vital interests
            </strong>
          </p>
          <p>
            To preserve, review and share information with law enforcement and
            others
          </p>
          <p>
            We may preserve, review and share information with law enforcement
            and others in circumstances where someone’s vital interests require
            protection, such as in the case of emergencies. For example, we may
            need to share information to prevent loss of life or personal injury
            of an MUSE Buyer or Seller or other third party or to prevent crime
            or fraud.
          </p>
          <h2>3. How We Share Your Information</h2>
          <p>
            We share your data with third parties where necessary to perform the
            Services, comply with our legal obligations, or for purposes set out
            in this Privacy Policy, including:
          </p>
          <p>
            Publicly Displayed Information: By using the Services, certain
            information about you will be publicly displayed on the Site and
            App, such as Buyer Public Profile Information and Seller Public
            Profile Information (as defined in Section 2). Buyers and Sellers
            can choose to make certain information and activities private
            through their account or Public Profile settings. Other information
            connected with your use of the Services will also be publicly
            displayed, including the date you joined, information relating to
            items you review, photos or videos of items you purchased, your
            saved product listings that are grouped by you (“Wishlist”),
            followers, those you follow, comments you post in our community
            spaces, and information you decide to share via social networks.
            Your reviews of shops or items you’ve purchased may also be shared
            on other platforms and search engines, including Google.
          </p>
          <p>
            For Sellers, some additional information will be publicly displayed.
            For example, your shop name or business name associated with your
            account (which you may modify and update in your account settings)
            is publicly displayed.
          </p>
          <p>
            MUSE Group Companies: We may share some of the information that we
            collect, such as Buyer Account Information, Seller Account
            Information, Service Usage Information, Device and Browser
            Information, and Cookie Information, with our corporate affiliates,
            which include MUSE Technology Pty Ltd, MUSE &amp; Partners Pty Ltd,
            MUSE Global Limited and other MUSE entities that help us conduct
            business around the world (together, the “MUSE Group Companies”). We
            do not share your personal information with other companies. The
            information shared among the MUSE Group Companies may be used for
            the purposes described in this Privacy Policy and generally to
            operate our business, including for ID verification purposes,
            transaction monitoring and payment processing or to provide customer
            support, facilitate business development, administer user accounts
            or provide IT, technical and engineering support.
          </p>
          <p>
            Sellers: We may share aggregated information about your use of the
            Services to Sellers if you are a frequent purchaser or a new Buyer,
            so they can provide you with better service and so we can improve
            our Site and App.
          </p>
          <p>
            Linked Third-Party Websites or Services: Some Users may choose to
            connect to MUSE or register a MUSE account using an external
            third-party application, such as Facebook or Google. Connecting your
            MUSE account to third-party applications or services is optional. If
            you choose to connect your account to a third-party application, the
            providers of those services or products may receive information
            about you that MUSE, you, or others share with them. Please be aware
            that when you use third-party sites or services, their own terms and
            privacy policies will govern your use of those sites or services.
          </p>
          <p>
            You may also link your bank account or payment method to pay for
            purchases (or receive payment, in the case of Sellers in select
            markets) through a third-party service available through the Site or
            MUSE App. When you register with these third-party partners, we will
            tell you what information is collected and what information the
            third-party will share with MUSE.
          </p>
          <p>
            Business Partners: MUSE works closely with a number of business
            partners, such as our payment partners, that assist us with
            providing the Services in accordance with our Terms of Use. When one
            of these companies assists us in facilitating your transaction, we,
            or they, may need to share information related to the transaction in
            order to complete your order. MUSE also provides services or sells
            products jointly with affiliated businesses, such as services that
            assist our Sellers with accounting or other small business
            management services. These affiliated businesses may sell items or
            services to you through the Services or offer promotions (including
            email promotions). In each case, these partners will process your
            personal information in accordance with their own privacy policies.
            We encourage you to read the privacy policies and ask questions of
            third parties before you disclose your personal information to them.
          </p>
          <p>
            Professional Advisors, Industry Partners, Authorities and
            Regulators: We share your information described in Section 3 with
            our advisors and consultants, regulators and tax authorities, law
            enforcement, government agencies, civil litigants, and industry
            partners (for example, trade groups) to:
          </p>
          <p>
            – comply with applicable law or regulations, court orders,
            subpoenas, legal process or government requests;
          </p>
          <p>
            – detect, investigate, prevent, or address actual or suspected
            fraud, violations of MUSE’s Terms of Use, and other illegal activity
            or security and technical issues; and/or
          </p>
          <p>
            – protect the rights, property and safety of our Users, MUSE and the
            MUSE Group Companies, or others, including to prevent death or
            imminent bodily harm.
          </p>
          <p>
            Business Reorganization: In some cases, MUSE may choose to
            reorganize its business. In these types of transactions (such as a
            sale, merger, liquidation, receivership, or transfer of all or
            substantially all of MUSE’s assets), Member information is typically
            one of the business assets that is transferred. If MUSE intends to
            transfer information about you, MUSE will notify you by email or by
            putting a notice on the Site and the App.
          </p>
          <p>
            Community Services: We engage third parties to assist in providing
            Community Services, which includes the MUSE Forums and Teams, and
            need to share certain information about you for these purposes. This
            may include, for example, Buyer Public Profile Information and
            Seller Public Profile Information, the date that you registered on
            MUSE, as well as your language and regional settings on the Site.
            For more information, see our Community Policy.
          </p>
          <p>
            Service Providers: MUSE also engages third-party companies and
            individuals (such as research companies, analytics services, and
            security providers) to help us operate, provide, and advertise the
            Services. These third parties have limited access to your
            information, may use your information only to perform these tasks on
            our behalf, and are prohibited from disclosing or using your
            information for other purposes. The types of third-party service
            providers that we work with include:
          </p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Third-Party Service Providers
            </strong>
          </p>
          <p>– Research companies</p>
          <p>– Analytics providers</p>
          <p>– Security providers</p>
          <p>– Software service providers</p>
          <p>– Infrastructure and technical service providers</p>
          <p>– Professional service providers</p>
          <p>– Customer service or other outsourced service providers</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              What Information We Share
            </strong>
          </p>
          <p>– Buyer Public Profile Information</p>
          <p>– Seller Public Profile Information</p>
          <p>– Buyer Account Information</p>
          <p>– Seller Account Information</p>
          <p>– Transaction Information</p>
          <p>– General Location Information</p>
          <p>– Photos and Videos</p>
          <p>– Customer Support Information</p>
          <p>– Browser and Device Information</p>
          <p>– Service Usage Information</p>
          <p>– Survey Responses and Market Research</p>
          <p>– Analytics Information</p>
          <h2>4. How We Transfer Your Information</h2>
          <p>
            MUSE operates a global e-commerce platform. By using the Services,
            you acknowledge that MUSE will use your information in the
            Australia, UK and any other country where the MUSE Group Companies
            operate. Please be aware that the privacy laws and standards in
            certain countries, including the rights of authorities to access
            your personal information, may differ from those that apply in the
            country in which you reside.
          </p>
          <h2>5. How We Protect Your Information</h2>
          <p>
            The security of your personal information is important to us. We
            protect your personal information through technical and
            administrative measures designed to mitigate the risk of unlawful or
            unauthorized access, destruction, loss, alteration, disclosure or
            use of your data. To protect your account, we encourage you to
            choose your password carefully. You can learn more about enhancing
            your account security in your account settings.
          </p>
          <h2>6. How Long We Store Your Information</h2>
          <p>
            MUSE will retain your information only for as long as is necessary
            for the purposes set out in this Policy, for as long as your account
            is active, or as needed to comply with our legal obligations under
            national applicable law. We decide how long we need information on a
            case-by-case basis and depending on the retention laws applicable.
          </p>
          <p>&nbsp;</p>
          <p>The following factors typically affect the retention period:</p>
          <p>&nbsp;</p>
          <p>
            Necessity for the provision of our Services We keep your information
            as long as is necessary to provide the Services to you.
          </p>
          <p>&nbsp;</p>
          <p>
            This includes things like maintaining and improving the performance
            of our Site and App, keeping our Services secure, and maintaining
            appropriate business and financial records. Most of our retention
            periods are determined on the basis of this general rule.
          </p>
          <p>&nbsp;</p>
          <p>
            Consent-based processing of personal data If we process personal
            data on the basis of consent (including consent to the extended
            storage), we store the data for as long as necessary in order to
            process it according to your consent or until you withdraw your
            consent.
          </p>
          <p>&nbsp;</p>
          <p>
            Statutory, contractual or other similar obligations MUSE will retain
            and use your information to the extent necessary to comply with our
            legal obligations, for example, if we are required to retain your
            information to comply with applicable tax/revenue laws or our Know
            Your Customer (“KYC”) obligations.
          </p>
          <p>&nbsp;</p>
          <p>
            Here’s what we consider when we deciding how long to keep your
            information:
          </p>
          <p>&nbsp;</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Purpose of Processing
            </strong>
          </p>
          <p>Information used to provide you with our Services</p>
          <p>&nbsp;</p>
          <p>
            <strong style={{ backgroundColor: "initial" }}>
              Retention Period
            </strong>
          </p>
          <p>
            Information we use to offer you our Services is retained for the
            lifetime of your account.
          </p>
          <p>&nbsp;</p>
          <p>
            Information used for regulatory compliance and other legal
            obligations
          </p>
          <p>
            MUSE will retain and use your information to the extent necessary to
            comply with our legal obligations, for example, if we are required
            to retain your information to comply with applicable tax/revenue
            laws or our Know Your Customer (“KYC”) obligations.
          </p>
          <p>&nbsp;</p>
          <p>Information used for other legitimate purposes</p>
          <p>
            We may retain your information for other legitimate purposes, such
            as to prevent harm; investigate possible violations of our House
            Rules and Policies; promote safety, security and integrity; or
            protect MUSE, including our rights, property or platform.
          </p>
          <h2>7. Your Rights and Choices</h2>
          <p>
            Certain privacy laws around the world, including the European
            General Data Protection Regulation (GDPR), provide users with rights
            and choices related to their personal information. Consistent with
            those laws, MUSE gives you the choice of accessing, editing, or
            removing certain information, as well as choices about how we
            contact you. In addition, subject to applicable law, you may have
            certain rights with respect to your personal information, such as
            the following:
          </p>
          <ul>
            <li>
              Right to Access: You can access certain personal information
              associated with your account by visiting your account privacy
              settings. You can request a copy of your personal information in
              an easily accessible format, as well as information explaining how
              that information is used. See this Help Center article for more
              information.
            </li>
            <li>
              <br />
            </li>
            <li>
              Right to Portability: You have the right to receive certain of
              your information which you provided in a structured, commonly used
              and machine-readable format and to transmit such information to
              another controller.
            </li>
            <li>
              <br />
            </li>
            <li>
              Right to Correction: You have the right to request that we rectify
              any inaccurate information about you. By visiting your account
              settings, you can correct and change certain personal information
              associated with your account.
            </li>
            <li>
              <br />
            </li>
            <li>
              Right to Restrict Processing: In certain cases where we process
              your information, you may also have the right to restrict or limit
              the ways in which we use your personal information.
            </li>
            <li>
              <br />
            </li>
            <li>
              Right to Deletion: In certain circumstances, you have the right to
              request the deletion of your personal information, except
              information we are required to retain by law, regulation, or to
              protect the safety, security, rights, and integrity of MUSE.
              Please note that closing your account may not free up your email
              address, username, or shop name (if any) for reuse on a new
              account. See this Help Center article for more information.
            </li>
            <li>
              <br />
            </li>
            <li>
              Right to Object: If we process your information based on our
              legitimate interests as explained above, or in the public
              interest, you can object to this processing in certain
              circumstances. In such cases, we will cease processing your
              information unless we have compelling legitimate grounds to
              continue processing or where it is needed for legal reasons.
            </li>
            <li>
              <br />
            </li>
            <li>
              Right to Withdraw Consent: Where we rely on consent, you can
              choose to withdraw your consent to our processing of your
              information using, for example, an email unsubscribe link or your
              account privacy preferences. If you have consented to share your
              precise device location details with us, you can revoke that
              consent through the settings on your mobile device. This is
              without prejudice to your right to generally permanently close
              your account and delete your personal information. Please be aware
              that withdrawing consent will not affect the lawfulness of
              processing based on consent before its withdrawal
            </li>
            <li>
              <br />
            </li>
            <li>
              Right to File a Complaint: If you are a resident in the EEA,
              Switzerland or the UK, you have the right to lodge a complaint
              about our practices with respect to your personal information with
              the supervisory authority of your country or state.
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            How to Exercise Your Rights and Choices. If you would like to
            manage, change, limit, or delete your personal information, you can
            do so via your MUSE account settings. Alternatively, you can
            exercise any of the rights above, subject to applicable law, through
            the contact options set out in Section 11. Once you contact us to
            exercise any of your rights, we will confirm receipt of your
            request. Limiting use of, or deleting, your personal information may
            impact features and uses that rely on that information.
          </p>
          <p>&nbsp;</p>
          <p>
            Communication Choices. You can opt out of receiving marketing emails
            by using the unsubscribe link in our marketing emails or through
            your account settings. Certain communications from MUSE are
            service-related or legally required and you will continue to receive
            them even if you opt out of marketing emails.
          </p>
          <h2>8. Your Responsibilities</h2>
          <p>
            If you are a Seller on our Services or use MUSE’s API, you may
            receive certain personal information and will have legal obligations
            in respect of this information. Your privacy responsibilities,
            including when you act as an independent data controller (someone
            who decides what personal data to collect and the purpose you use
            the data for) are laid out in the MUSE Seller Policy and the MUSE
            API Terms of Use.
          </p>
          <h2>9. Privacy Policy Changes</h2>
          <p>
            We may amend or update this policy from time to time. If we believe
            that the changes are material, we’ll let you know by posting the
            changes through the Services and sending you an email or message
            about the changes. We encourage you to check back regularly and
            review any updates.
          </p>
          <h2>
            10. How you may complain about how we deal with your personal
            information
          </h2>
          <p>
            If you have any queries or complaints about our collection, use or
            storage of your personal information (including a complaint relating
            to any failure by us to comply with our obligations under the credit
            reporting provisions of the&nbsp;Privacy Act 1988(Cth) or under the
            Privacy (Credit Reporting Code 2014), or if you wish to exercise any
            of your rights in relation to your personal information, please
            contact complaints@jointhemuse.com and address your email to The
            Privacy Officer. We will investigate and attempt to resolve any such
            complaint or dispute regarding the use or disclosure of your
            personal information.
          </p>
          <p>&nbsp;</p>
          <p>
            If you are dissatisfied with the handling of your complaint, you may
            contact the Office of the Australian Information Commissioner or
            Australian Financial Complaints Authority (AFCA):
          </p>
          <p>&nbsp;</p>
          <p>Office of the Australian Information Commissioner</p>
          <p>GPO Box 5218, Sydney NSW 2001</p>
          <p>Telephone: 1300 363 992</p>
          <p>Mail: GPO Box 5218 Sydney NSW 2001</p>
          <p>Fax: +61 2 9284 9666</p>
          <p>Email: enquiries@oaic.gov.au</p>
          <h2>11. How to Contact Us</h2>
          <p>If you have any questions:</p>
          <p>– Contact MUSE’s Support team via our Help Center</p>
          <p>
            – Send an email to MUSE’s Data Protection Officer at
            help@jointhemuse.com
          </p>
          <p>
            <br />
          </p>
        </Box>
      </Box>
    </Box>
  );
}
