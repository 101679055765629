import React from "react";
import "./App.css";

import { Box } from "@mui/material";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Router from "./router";

function App() {
  return (
    <Box>
      <Header />
      <Box sx={{ paddingTop: "110px" }}>
        <Router />
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
