import { Box, Grid, Link, Stack } from "@mui/material";
import { Logo } from "../assets";
import RouterLink from "../router/RouterLink";

export default function Footer() {
  return (
    <Box
      height={{ xs: 300, md: 160 }}
      sx={{
        background: "#EDE6DC",
        width: "100%",
        bottom: 0,
      }}
    >
      <Box
        maxWidth={1324}
        sx={{
          margin: "auto",
          width: "100%",
          borderTop: "1px solid white",
          paddingTop: "26px",
        }}
      >
        <Grid
          container
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
          p={{ md: 0, xs: "20px" }}
        >
          <Grid xs={12} md={9}>
            <Stack direction={{ md: "row", xs: "column" }} width={"100%"}>
              <Box mr={2} mb={2}>
                <Logo />
              </Box>
              <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={1}
                justifyContent={"center"}
              >
                <Box
                  sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 12 }}
                >
                  Sydney:
                </Box>
                <Box
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: 300,
                    color: "black",

                    fontSize: 12,
                  }}
                >
                  Suite 16, Level 2, 123 Clarence Street, Sydney NSW 2000
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid mt={{ md: 0, xs: 2 }} xs={12} md={3}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack width={"100%"}>
                <Box
                  sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 12 }}
                >
                  Resources
                </Box>
                <Stack mt={2} spacing={1}>
                  <Link
                    sx={{ textDecoration: "none" }}
                    component={RouterLink}
                    href={"/end-user-licence-agreement"}
                  >
                    <Box
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: 300,
                        color: "black",

                        fontSize: 12,
                      }}
                    >
                      EULA
                    </Box>
                  </Link>
                  <Link
                    sx={{ textDecoration: "none" }}
                    component={RouterLink}
                    href={"/terms-conditions"}
                  >
                    <Box
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: 300,
                        color: "black",

                        fontSize: 12,
                      }}
                    >
                      Terms & conditions
                    </Box>
                  </Link>
                  <Link
                    sx={{ textDecoration: "none" }}
                    component={RouterLink}
                    href={"/privacy-policy"}
                  >
                    <Box
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: 300,
                        color: "black",
                        fontSize: 12,
                      }}
                    >
                      Privacy policy
                    </Box>
                  </Link>
                </Stack>
              </Stack>
              <Stack width={"100%"}>
                <Box
                  sx={{ fontFamily: "Inter", fontWeight: 700, fontSize: 12 }}
                >
                  Contact
                </Box>
                <Stack mt={2} spacing={1}>
                  <Box
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 300,
                      color: "black",
                      fontSize: 12,
                    }}
                  >
                    info@jointhemuse.com
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
