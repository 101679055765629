import { Box, BoxProps } from "@mui/material";

export const images = {
  banner: require("./images/banner.png"),
  preview: require("./images/preview.png"),
};

export const icons = {
  appStore: require("./icons/appstore.png"),
  googlePlay: require("./icons/google-play.png"),
};

export function Logo({ ...other }: BoxProps) {
  return (
    <Box
      component="svg"
      width={39}
      height={31}
      viewBox="0 0 39 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g clipPath="url(#clip0_1_240)">
        <path
          d="M5.24029 0C5.26098 0.0286904 5.29441 0.021518 5.31987 0.0262997C5.85464 0.12831 6.24856 0.419996 6.4666 0.920485C6.59133 1.20994 6.62273 1.53116 6.55643 1.83934C6.49012 2.14753 6.32943 2.4273 6.09676 2.63964C5.86408 2.85198 5.57103 2.9863 5.25848 3.02386C4.94593 3.06141 4.62947 3.00034 4.35323 2.84915C4.077 2.69797 3.85478 2.46421 3.71756 2.18048C3.58034 1.89676 3.53497 1.57721 3.58777 1.26643C3.64057 0.955638 3.78892 0.669105 4.01212 0.446796C4.23532 0.224486 4.52223 0.0774856 4.83285 0.0262997C4.86548 0.020721 4.89731 0.00876652 4.92914 0H5.24029Z"
          fill="#202020"
        />
        <path
          d="M34.0629 6.12263e-06C34.0909 0.00944872 34.1193 0.0174293 34.1481 0.023915C34.4986 0.076557 34.8196 0.250333 35.0556 0.515131C35.2916 0.779929 35.4277 1.11906 35.4403 1.47378C35.4528 1.82849 35.3411 2.17643 35.1244 2.45731C34.9078 2.7382 34.5998 2.93432 34.2539 3.0117C33.8614 3.10249 33.449 3.03342 33.1074 2.81969C32.7658 2.60597 32.523 2.26509 32.4323 1.87206C32.3417 1.47902 32.4107 1.06602 32.6241 0.723911C32.8375 0.381801 33.1779 0.138606 33.5703 0.047824C33.6423 0.0414133 33.7131 0.0247766 33.7804 -0.00158691L34.0629 6.12263e-06Z"
          fill="#202020"
        />
        <path
          d="M1.66635 0C1.69818 0.00876652 1.73001 0.0207218 1.76185 0.0270974C2.39848 0.160189 2.82581 0.533962 2.98497 1.16914C3.05471 1.45983 3.03696 1.7647 2.93395 2.04531C2.83095 2.32592 2.6473 2.56971 2.40617 2.74596C2.16503 2.92221 1.87719 3.02303 1.57892 3.03571C1.28066 3.04838 0.985327 2.97235 0.730142 2.8172C0.474958 2.66205 0.271346 2.43472 0.14497 2.16386C0.0185944 1.893 -0.0248893 1.59073 0.0199993 1.29516C0.064888 0.999594 0.196139 0.723947 0.397212 0.502964C0.598285 0.281981 0.860178 0.125553 1.14988 0.0533971C1.22144 0.0474009 1.29158 0.0299091 1.35759 0.00159425L1.66635 0Z"
          fill="#202020"
        />
        <path
          d="M39 1.67521C38.9904 1.69347 38.9837 1.71309 38.9801 1.73339C38.9707 1.94051 38.9043 2.14101 38.7883 2.31277C38.6577 2.53649 38.4706 2.72191 38.2459 2.85044C38.0212 2.97898 37.7667 3.04614 37.5079 3.04517C37.1274 3.06208 36.7545 2.93452 36.4638 2.688C36.1731 2.44148 35.986 2.09416 35.94 1.71549C35.8939 1.33682 35.9923 0.95469 36.2154 0.645508C36.4385 0.336327 36.77 0.122874 37.1434 0.047824L37.3543 -0.00158691H37.6368C37.6687 0.0071796 37.7005 0.0191341 37.7331 0.0247128C37.9552 0.0603575 38.1664 0.14536 38.3514 0.273479C38.5363 0.401599 38.6902 0.56958 38.8019 0.765085C38.9085 0.932469 38.9699 1.12469 38.9801 1.32295C38.9838 1.34759 38.9904 1.37168 39 1.39468V1.67521Z"
          fill="#202020"
        />
        <path
          d="M30.1294 31L30.0371 30.9578C29.9204 30.9067 29.8253 30.8164 29.7683 30.7024C29.7113 30.5884 29.6959 30.458 29.725 30.3339C29.754 30.2097 29.8256 30.0997 29.9273 30.023C30.0289 29.9462 30.1542 29.9076 30.2814 29.9138C30.401 29.9203 30.5151 29.9662 30.606 30.0443C30.697 30.1225 30.7596 30.2284 30.7843 30.3458C30.8089 30.4633 30.7942 30.5855 30.7424 30.6937C30.6906 30.8019 30.6047 30.89 30.4978 30.9442L30.3856 31H30.1294Z"
          fill="#202020"
        />
        <path
          d="M33.8059 31C33.7637 30.9809 33.7208 30.9633 33.6786 30.9418C33.5822 30.8918 33.503 30.8141 33.4511 30.7187C33.3991 30.6233 33.3769 30.5145 33.3871 30.4063C33.3974 30.2981 33.4397 30.1954 33.5087 30.1115C33.5776 30.0276 33.67 29.9662 33.7741 29.9353C33.9035 29.8994 34.0417 29.9128 34.1619 29.9728C34.2821 30.0328 34.376 30.1352 34.4254 30.2603C34.4747 30.3854 34.4762 30.5244 34.4294 30.6505C34.3826 30.7766 34.2909 30.8809 34.172 30.9434L34.0598 31H33.8059Z"
          fill="#202020"
        />
        <path
          d="M1.51439 3.80228C1.79309 3.80197 2.06561 3.88451 2.29742 4.03944C2.52924 4.19438 2.70992 4.41473 2.81657 4.67259C2.92322 4.93045 2.95105 5.21422 2.89652 5.48793C2.84199 5.76164 2.70757 6.01299 2.51028 6.21012C2.31299 6.40725 2.0617 6.54131 1.78827 6.59529C1.51484 6.64928 1.23155 6.62077 0.974312 6.51338C0.717071 6.40598 0.49745 6.22454 0.343269 5.99204C0.189089 5.75953 0.107289 5.48642 0.108234 5.20732C0.107281 5.02227 0.143025 4.83888 0.213393 4.66777C0.283761 4.49666 0.387353 4.34125 0.518159 4.21055C0.648965 4.07985 0.804383 3.97646 0.975398 3.90638C1.14641 3.83629 1.32962 3.80091 1.51439 3.80228Z"
          fill="#202020"
        />
        <path
          d="M7.23847 5.21449C7.23768 4.93549 7.31958 4.66252 7.47378 4.43016C7.62799 4.19779 7.84757 4.01647 8.10473 3.90915C8.3619 3.80184 8.64507 3.77335 8.91841 3.8273C9.19175 3.88124 9.44296 4.0152 9.64023 4.21221C9.83751 4.40921 9.97198 4.66041 10.0266 4.934C10.0813 5.20758 10.0536 5.49125 9.94719 5.7491C9.84075 6.00694 9.66032 6.22736 9.42874 6.38245C9.19715 6.53754 8.92482 6.62032 8.64622 6.62032C8.27381 6.61949 7.91683 6.47123 7.65312 6.20788C7.38941 5.94453 7.24036 5.58745 7.23847 5.21449Z"
          fill="#202020"
        />
        <path
          d="M5.08671 3.80228C5.45417 3.81173 5.80337 3.96475 6.05967 4.22862C6.31597 4.4925 6.45905 4.84631 6.45832 5.21443C6.45759 5.58255 6.31312 5.93578 6.05578 6.19864C5.79843 6.4615 5.44863 6.61313 5.08114 6.62112C4.89351 6.6252 4.70696 6.59167 4.53246 6.52249C4.35795 6.45332 4.19902 6.3499 4.06501 6.21831C3.93101 6.08673 3.82462 5.92964 3.75212 5.75628C3.67961 5.58292 3.64245 5.39679 3.64283 5.20884C3.6432 5.02089 3.68109 4.83492 3.75428 4.66185C3.82747 4.48878 3.93447 4.33211 4.069 4.20106C4.20353 4.07001 4.36287 3.96722 4.53764 3.89874C4.71241 3.83025 4.8991 3.79746 5.08671 3.80228Z"
          fill="#202020"
        />
        <path
          d="M35.3306 5.20889C35.3347 5.39669 35.3013 5.58343 35.2323 5.75811C35.1634 5.93279 35.0602 6.0919 34.9289 6.22607C34.7976 6.36024 34.6409 6.46677 34.4679 6.53939C34.2949 6.61201 34.1092 6.64926 33.9216 6.64894C33.734 6.64862 33.5484 6.61074 33.3757 6.53753C33.2029 6.46433 33.0465 6.35727 32.9157 6.22265C32.7849 6.08803 32.6823 5.92858 32.6139 5.75366C32.5455 5.57874 32.5127 5.3919 32.5175 5.20411C32.5268 4.83632 32.6794 4.48678 32.9427 4.23018C33.206 3.97358 33.559 3.83028 33.9264 3.8309C34.2937 3.83153 34.6463 3.97603 34.9087 4.23352C35.1711 4.49101 35.3225 4.84107 35.3306 5.20889Z"
          fill="#202020"
        />
        <path
          d="M36.0866 5.20573C36.0723 4.43587 36.7288 3.79193 37.5047 3.7991C37.8783 3.80185 38.2354 3.9531 38.4977 4.21958C38.7599 4.48607 38.9056 4.84596 38.9029 5.22007C38.9001 5.59419 38.7491 5.95189 38.483 6.21449C38.2169 6.47709 37.8576 6.62307 37.484 6.62033C37.2991 6.62076 37.1159 6.58436 36.9452 6.51324C36.7745 6.44212 36.6196 6.33769 36.4895 6.20605C36.3595 6.0744 36.2569 5.91816 36.1877 5.74643C36.1185 5.57469 36.0842 5.39089 36.0866 5.20573Z"
          fill="#202020"
        />
        <path
          d="M30.2487 3.80228C30.4364 3.79781 30.623 3.83096 30.7976 3.89978C30.9723 3.96861 31.1314 4.07171 31.2657 4.20303C31.4 4.33434 31.5067 4.49123 31.5796 4.66445C31.6524 4.83767 31.6899 5.02374 31.6899 5.2117C31.6899 5.39966 31.6524 5.58572 31.5796 5.75894C31.5067 5.93216 31.4 6.08905 31.2657 6.22037C31.1314 6.35169 30.9723 6.45479 30.7976 6.52361C30.623 6.59243 30.4364 6.62558 30.2487 6.62111C29.8813 6.61236 29.5318 6.46003 29.275 6.19667C29.0182 5.9333 28.8745 5.5798 28.8745 5.2117C28.8745 4.8436 29.0182 4.49009 29.275 4.22673C29.5318 3.96337 29.8813 3.81103 30.2487 3.80228Z"
          fill="#202020"
        />
        <path
          d="M33.9237 10.0807C33.6666 10.0801 33.4155 10.003 33.2022 9.85938C32.9889 9.71573 32.8229 9.51191 32.7254 9.27373C32.6278 9.03555 32.603 8.77373 32.6541 8.52143C32.7052 8.26913 32.83 8.03769 33.0125 7.85644C33.1951 7.67518 33.4272 7.55225 33.6796 7.50323C33.9319 7.45421 34.1932 7.48129 34.4301 7.58105C34.6671 7.6808 34.8692 7.84875 35.0108 8.06361C35.1524 8.27848 35.2272 8.53059 35.2256 8.78803C35.2216 9.50848 34.6144 10.103 33.9237 10.0807Z"
          fill="#202020"
        />
        <path
          d="M8.64864 10.0807C7.977 10.1046 7.34992 9.53718 7.34514 8.78565C7.34045 8.61201 7.37049 8.43917 7.4335 8.27733C7.49652 8.11549 7.59123 7.96791 7.71205 7.84328C7.83288 7.71865 7.97738 7.61949 8.13706 7.55164C8.29673 7.48378 8.46835 7.44861 8.6418 7.44819C8.81525 7.44776 8.98704 7.48209 9.14704 7.54916C9.30705 7.61623 9.45203 7.71468 9.57347 7.83872C9.6949 7.96275 9.79033 8.10987 9.85414 8.2714C9.91794 8.43293 9.94883 8.60561 9.94498 8.77927C9.94498 9.5316 9.31551 10.1006 8.64864 10.0807Z"
          fill="#202020"
        />
        <path
          d="M5.07556 10.0807C4.90237 10.0843 4.73021 10.0532 4.56921 9.98914C4.40822 9.92511 4.26164 9.82948 4.1381 9.70786C4.01457 9.58625 3.91658 9.44112 3.84989 9.281C3.78321 9.12089 3.74919 8.94903 3.74983 8.77555C3.75046 8.60208 3.78575 8.43048 3.8536 8.27086C3.92146 8.11124 4.02052 7.96683 4.14494 7.84613C4.26936 7.72543 4.41664 7.63087 4.5781 7.56803C4.73957 7.50519 4.91195 7.47534 5.08511 7.48022C5.2583 7.47661 5.43046 7.50774 5.59145 7.57177C5.75245 7.63579 5.89903 7.73143 6.02256 7.85305C6.1461 7.97466 6.24409 8.1198 6.31077 8.27991C6.37745 8.44002 6.41148 8.61187 6.41084 8.78535C6.4102 8.95883 6.37492 9.13043 6.30706 9.29005C6.23921 9.44967 6.14015 9.59408 6.01573 9.71478C5.8913 9.83548 5.74403 9.93004 5.58256 9.99288C5.4211 10.0557 5.24872 10.0856 5.07556 10.0807Z"
          fill="#202020"
        />
        <path
          d="M26.6852 10.0807C26.0048 10.1014 25.3888 9.51805 25.3873 8.78087C25.3832 8.6072 25.414 8.43448 25.4776 8.27288C25.5413 8.11128 25.6365 7.96407 25.7579 7.83991C25.8792 7.71576 26.0241 7.61716 26.184 7.54994C26.344 7.48272 26.5157 7.44822 26.6892 7.44849C26.8627 7.44875 27.0343 7.48377 27.1941 7.55149C27.3538 7.6192 27.4984 7.71824 27.6194 7.84277C27.7403 7.96729 27.8351 8.1148 27.8983 8.27659C27.9614 8.43839 27.9916 8.6112 27.9871 8.78486C27.9882 8.956 27.9552 9.12564 27.89 9.28387C27.8249 9.4421 27.7289 9.58573 27.6077 9.70638C27.4864 9.82703 27.3424 9.92227 27.184 9.98654C27.0257 10.0508 26.8561 10.0828 26.6852 10.0807Z"
          fill="#202020"
        />
        <path
          d="M30.2384 10.0807C29.982 10.0777 29.7323 9.99881 29.5207 9.85395C29.309 9.70909 29.1449 9.50475 29.049 9.26665C28.9532 9.02856 28.9298 8.76737 28.9818 8.516C29.0339 8.26462 29.1591 8.0343 29.3416 7.85404C29.5242 7.67379 29.7559 7.55166 30.0076 7.50305C30.2593 7.45444 30.5198 7.48152 30.7561 7.58088C30.9925 7.68024 31.1942 7.84743 31.3359 8.0614C31.4776 8.27536 31.5528 8.52653 31.5522 8.78325C31.5506 9.54514 30.9084 10.111 30.2384 10.0807Z"
          fill="#202020"
        />
        <path
          d="M37.492 10.0807C36.7965 10.1014 36.1933 9.50291 36.1909 8.78326C36.1866 8.60959 36.2171 8.43682 36.2804 8.27511C36.3438 8.11341 36.4389 7.96604 36.56 7.84169C36.6812 7.71734 36.8259 7.61852 36.9858 7.55105C37.1456 7.48358 37.3173 7.44882 37.4908 7.44882C37.6643 7.44882 37.836 7.48358 37.9958 7.55105C38.1557 7.61852 38.3004 7.71734 38.4216 7.84169C38.5427 7.96604 38.6378 8.11341 38.7012 8.27511C38.7646 8.43682 38.795 8.60959 38.7907 8.78326C38.792 8.95427 38.7593 9.12382 38.6944 9.28202C38.6296 9.44022 38.5339 9.5839 38.413 9.70467C38.2921 9.82545 38.1484 9.9209 37.9903 9.98546C37.8321 10.05 37.6628 10.0824 37.492 10.0807Z"
          fill="#202020"
        />
        <path
          d="M1.51834 10.0807C0.844312 10.1054 0.218823 9.52282 0.215639 8.78484C0.215326 8.61394 0.248629 8.44464 0.313646 8.28662C0.378664 8.12861 0.474121 7.98496 0.594572 7.86389C0.715022 7.74282 0.858106 7.6467 1.01565 7.581C1.1732 7.51531 1.34212 7.48133 1.51277 7.48102C1.68343 7.48071 1.85248 7.51406 2.01026 7.57917C2.16805 7.64428 2.31148 7.73988 2.43237 7.86051C2.55327 7.98114 2.64925 8.12443 2.71485 8.28221C2.78045 8.43998 2.81437 8.60915 2.81468 8.78006C2.8166 8.95119 2.78439 9.12098 2.71995 9.27949C2.65551 9.438 2.56014 9.58204 2.43941 9.70317C2.31868 9.82429 2.17503 9.92008 2.01687 9.9849C1.85871 10.0497 1.68923 10.0823 1.51834 10.0807Z"
          fill="#202020"
        />
        <path
          d="M12.318 10.0807C11.6018 10.099 11.0177 9.47261 11.0185 8.78164C11.0142 8.60798 11.0446 8.4352 11.108 8.2735C11.1714 8.1118 11.2665 7.96443 11.3876 7.84008C11.5088 7.71573 11.6535 7.61691 11.8134 7.54944C11.9732 7.48196 12.1449 7.4472 12.3184 7.4472C12.4919 7.4472 12.6636 7.48196 12.8234 7.54944C12.9833 7.61691 13.128 7.71573 13.2492 7.84008C13.3703 7.96443 13.4653 8.1118 13.5287 8.2735C13.5921 8.4352 13.6226 8.60798 13.6183 8.78164C13.6201 8.95301 13.5877 9.12301 13.523 9.28165C13.4582 9.44029 13.3625 9.58436 13.2413 9.70539C13.1202 9.82643 12.9761 9.92198 12.8175 9.98643C12.6589 10.0509 12.4891 10.0829 12.318 10.0807Z"
          fill="#202020"
        />
        <path
          d="M24.0949 12.4644C24.0944 12.6786 24.0306 12.8878 23.9115 13.0657C23.7923 13.2436 23.6232 13.3822 23.4255 13.4639C23.2278 13.5456 23.0104 13.5668 22.8006 13.5249C22.5909 13.4829 22.3982 13.3796 22.2471 13.2281C22.0959 13.0766 21.9929 12.8836 21.9512 12.6735C21.9094 12.4635 21.9308 12.2457 22.0125 12.0478C22.0943 11.8498 22.2327 11.6806 22.4104 11.5614C22.5882 11.4422 22.7972 11.3784 23.011 11.3781C23.1537 11.3773 23.2951 11.4048 23.427 11.4591C23.5589 11.5134 23.6788 11.5934 23.7796 11.6944C23.8804 11.7955 23.9602 11.9156 24.0143 12.0478C24.0685 12.1799 24.0958 12.3215 24.0949 12.4644Z"
          fill="#202020"
        />
        <path
          d="M13.3987 12.466C13.398 12.6802 13.334 12.8895 13.2147 13.0674C13.0953 13.2452 12.926 13.3836 12.7282 13.4651C12.5303 13.5467 12.3128 13.5676 12.103 13.5254C11.8933 13.4831 11.7007 13.3796 11.5497 13.2278C11.3987 13.076 11.296 12.8828 11.2546 12.6726C11.2132 12.4624 11.2349 12.2446 11.317 12.0467C11.3992 11.8489 11.538 11.6798 11.716 11.561C11.894 11.4421 12.1032 11.3788 12.3172 11.3789C12.4598 11.378 12.6012 11.4055 12.7331 11.4598C12.865 11.5141 12.9848 11.5942 13.0855 11.6954C13.1861 11.7966 13.2657 11.9169 13.3194 12.0492C13.3732 12.1815 13.4002 12.3232 13.3987 12.466Z"
          fill="#202020"
        />
        <path
          d="M14.8033 12.462C14.8041 12.2478 14.8682 12.0387 14.9876 11.8609C15.107 11.6832 15.2763 11.5449 15.4741 11.4635C15.672 11.3821 15.8894 11.3612 16.0991 11.4035C16.3088 11.4457 16.5013 11.5493 16.6522 11.701C16.8032 11.8527 16.9059 12.0459 16.9473 12.256C16.9887 12.4662 16.9671 12.6839 16.8851 12.8817C16.803 13.0795 16.6643 13.2485 16.4864 13.3675C16.3085 13.4864 16.0994 13.5499 15.8856 13.5499C15.7428 13.5508 15.6013 13.5233 15.4693 13.4689C15.3373 13.4146 15.2175 13.3344 15.1167 13.2331C15.016 13.1319 14.9364 13.0115 14.8826 12.8791C14.8287 12.7467 14.8018 12.6049 14.8033 12.462Z"
          fill="#202020"
        />
        <path
          d="M8.64066 13.5506C8.35299 13.5501 8.07731 13.4352 7.87427 13.2311C7.67123 13.027 7.55746 12.7505 7.55799 12.4624C7.55851 12.1743 7.6733 11.8982 7.87708 11.6949C8.08087 11.4916 8.35697 11.3776 8.64464 11.3781C8.93231 11.3787 9.20798 11.4936 9.41102 11.6977C9.61406 11.9018 9.72783 12.1783 9.7273 12.4664C9.72678 12.7545 9.612 13.0306 9.40821 13.2339C9.20442 13.4372 8.92833 13.5512 8.64066 13.5506Z"
          fill="#202020"
        />
        <path
          d="M2.59827 12.4628C2.59843 12.6773 2.53509 12.8871 2.41627 13.0656C2.29744 13.2442 2.12845 13.3834 1.93065 13.4658C1.73284 13.5482 1.51509 13.57 1.3049 13.5286C1.09471 13.4871 0.901505 13.3842 0.749687 13.2328C0.597869 13.0814 0.494249 12.8884 0.451915 12.6781C0.409581 12.4678 0.430433 12.2496 0.511834 12.0511C0.593236 11.8527 0.731536 11.6828 0.90927 11.5631C1.087 11.4433 1.2962 11.3789 1.51043 11.3781C1.65315 11.3772 1.79464 11.4046 1.92674 11.4587C2.05884 11.5128 2.17892 11.5926 2.28007 11.6934C2.38121 11.7943 2.4614 11.9142 2.51602 12.0462C2.57063 12.1783 2.59859 12.3199 2.59827 12.4628Z"
          fill="#202020"
        />
        <path
          d="M3.99803 12.4461C4.00204 12.2314 4.06961 12.0228 4.19217 11.8467C4.31473 11.6705 4.48676 11.5348 4.68645 11.4567C4.88613 11.3786 5.10447 11.3617 5.31379 11.408C5.5231 11.4544 5.71395 11.562 5.86214 11.7171C6.01033 11.8722 6.10918 12.0679 6.14616 12.2794C6.18314 12.4908 6.15657 12.7085 6.06984 12.9049C5.9831 13.1012 5.8401 13.2673 5.65897 13.3821C5.47784 13.497 5.26674 13.5553 5.05245 13.5498C4.9107 13.5472 4.77089 13.5164 4.64106 13.4594C4.51122 13.4024 4.39393 13.3202 4.29593 13.2176C4.19793 13.1151 4.12116 12.9941 4.07003 12.8616C4.0189 12.7292 3.99443 12.588 3.99803 12.4461Z"
          fill="#202020"
        />
        <path
          d="M27.7706 12.4588C27.7716 12.6733 27.709 12.8833 27.5909 13.0622C27.4727 13.2412 27.3043 13.381 27.1068 13.4642C26.9094 13.5473 26.6918 13.57 26.4814 13.5294C26.2711 13.4887 26.0776 13.3866 25.9251 13.2359C25.7727 13.0851 25.6684 12.8926 25.6252 12.6824C25.582 12.4723 25.6019 12.2541 25.6825 12.0554C25.763 11.8566 25.9006 11.6862 26.0778 11.5657C26.2549 11.4451 26.4638 11.3799 26.678 11.3781C26.8208 11.3766 26.9625 11.4033 27.0949 11.4569C27.2273 11.5105 27.3477 11.5898 27.4493 11.6903C27.5509 11.7908 27.6316 11.9105 27.6868 12.0424C27.7419 12.1743 27.7704 12.3158 27.7706 12.4588Z"
          fill="#202020"
        />
        <path
          d="M29.1704 12.4509C29.1733 12.236 29.2399 12.0268 29.3617 11.8498C29.4835 11.6729 29.655 11.5362 29.8546 11.457C30.0541 11.3779 30.2726 11.3599 30.4823 11.4053C30.6921 11.4507 30.8836 11.5576 31.0327 11.7122C31.1817 11.8668 31.2815 12.0623 31.3194 12.2739C31.3573 12.4854 31.3316 12.7034 31.2456 12.9003C31.1596 13.0972 31.017 13.2641 30.8362 13.3797C30.6553 13.4954 30.4442 13.5546 30.2296 13.5499C30.088 13.5475 29.9482 13.5171 29.8183 13.4606C29.6883 13.404 29.5708 13.3224 29.4724 13.2203C29.3741 13.1182 29.2967 12.9977 29.2449 12.8657C29.1931 12.7337 29.1678 12.5927 29.1704 12.4509Z"
          fill="#202020"
        />
        <path
          d="M38.5703 12.462C38.5703 12.6761 38.507 12.8853 38.3883 13.0633C38.2696 13.2413 38.101 13.3802 37.9036 13.4622C37.7062 13.5443 37.489 13.566 37.2793 13.5246C37.0696 13.4831 36.8769 13.3803 36.7255 13.2293C36.5741 13.0782 36.4708 12.8856 36.4286 12.6757C36.3864 12.4659 36.4073 12.2483 36.4885 12.0503C36.5698 11.8523 36.7078 11.6829 36.8851 11.5634C37.0624 11.4439 37.2711 11.3797 37.4848 11.379C37.6275 11.3773 37.7691 11.4041 37.9013 11.4579C38.0335 11.5116 38.1537 11.5913 38.2547 11.6921C38.3558 11.793 38.4358 11.913 38.49 12.0452C38.5442 12.1774 38.5715 12.3191 38.5703 12.462Z"
          fill="#202020"
        />
        <path
          d="M33.911 11.3781C34.1959 11.3759 34.4702 11.4859 34.6747 11.6845C34.8793 11.8832 34.9976 12.1544 35.0041 12.4397C35.0105 12.7249 34.9047 13.0013 34.7094 13.209C34.5141 13.4167 34.245 13.5392 33.9603 13.5499C33.8166 13.5552 33.6733 13.532 33.5387 13.4814C33.4041 13.4308 33.2808 13.3539 33.1761 13.2552C33.0715 13.1565 32.9874 13.038 32.9289 12.9064C32.8704 12.7749 32.8386 12.633 32.8353 12.4891C32.832 12.3451 32.8574 12.2019 32.9098 12.0679C32.9623 11.9338 33.0409 11.8116 33.141 11.7082C33.2411 11.6048 33.3607 11.5224 33.4929 11.4657C33.6251 11.4091 33.7672 11.3793 33.911 11.3781Z"
          fill="#202020"
        />
        <path
          d="M1.51437 17.0126C1.38539 17.0141 1.25742 16.9897 1.13805 16.9408C1.01868 16.8918 0.910323 16.8194 0.819414 16.7278C0.728505 16.6361 0.656889 16.5272 0.608812 16.4073C0.560735 16.2874 0.537176 16.1591 0.539528 16.03C0.540629 15.8369 0.598803 15.6485 0.70671 15.4886C0.814616 15.3286 0.967416 15.2043 1.14583 15.1312C1.32425 15.0581 1.52028 15.0396 1.70919 15.078C1.89809 15.1164 2.07141 15.21 2.20727 15.3469C2.34313 15.4838 2.43543 15.658 2.47254 15.8475C2.50964 16.0369 2.48989 16.2331 2.41576 16.4113C2.34163 16.5895 2.21646 16.7417 2.05604 16.8487C1.89561 16.9557 1.70713 17.0128 1.51437 17.0126Z"
          fill="#202020"
        />
        <path
          d="M26.6828 17.0126C26.5548 17.014 26.4278 16.9898 26.3093 16.9413C26.1907 16.8928 26.0831 16.8211 25.9926 16.7304C25.9022 16.6397 25.8307 16.5317 25.7825 16.4129C25.7343 16.2942 25.7103 16.1669 25.7119 16.0387C25.7113 15.8452 25.7681 15.6558 25.8751 15.4946C25.9822 15.3334 26.1346 15.2076 26.3131 15.1333C26.4916 15.059 26.6881 15.0395 26.8777 15.0772C27.0673 15.115 27.2414 15.2082 27.378 15.3452C27.5146 15.4822 27.6074 15.6568 27.6448 15.8467C27.6822 16.0366 27.6623 16.2334 27.5879 16.4121C27.5134 16.5907 27.3876 16.7431 27.2265 16.8501C27.0653 16.957 26.8761 17.0136 26.6828 17.0126Z"
          fill="#202020"
        />
        <path
          d="M30.2591 17.0126C30.1305 17.0133 30.0031 16.9886 29.8841 16.9398C29.7652 16.891 29.657 16.8192 29.5659 16.7284C29.4747 16.6376 29.4025 16.5296 29.3532 16.4107C29.3039 16.2918 29.2786 16.1643 29.2787 16.0355C29.2752 15.9048 29.298 15.7748 29.3456 15.653C29.3932 15.5313 29.4647 15.4203 29.5559 15.3267C29.6471 15.2331 29.7561 15.1588 29.8765 15.1082C29.9969 15.0575 30.1262 15.0316 30.2567 15.0318C30.3873 15.0321 30.5165 15.0586 30.6367 15.1097C30.7568 15.1609 30.8655 15.2356 30.9563 15.3296C31.0472 15.4235 31.1182 15.5348 31.1654 15.6567C31.2125 15.7787 31.2347 15.9088 31.2307 16.0395C31.2303 16.2975 31.1278 16.5447 30.9457 16.7271C30.7635 16.9095 30.5166 17.0122 30.2591 17.0126Z"
          fill="#202020"
        />
        <path
          d="M15.8911 17.0126C15.698 17.0139 15.5089 16.9577 15.3477 16.8511C15.1866 16.7446 15.0606 16.5925 14.9859 16.4142C14.9111 16.2359 14.891 16.0393 14.9279 15.8495C14.9649 15.6597 15.0573 15.4852 15.1935 15.348C15.3296 15.2109 15.5034 15.1173 15.6927 15.0792C15.882 15.0411 16.0784 15.0602 16.2569 15.134C16.4354 15.2078 16.5879 15.3331 16.6952 15.4939C16.8025 15.6547 16.8597 15.8437 16.8596 16.0371C16.8592 16.2948 16.7572 16.5418 16.5758 16.7245C16.3944 16.9072 16.1484 17.0107 15.8911 17.0126Z"
          fill="#202020"
        />
        <path
          d="M5.07633 17.0126C4.94774 17.0123 4.82049 16.9865 4.70191 16.9367C4.58333 16.8869 4.47577 16.8141 4.38544 16.7224C4.29511 16.6308 4.22379 16.5221 4.1756 16.4027C4.12741 16.2833 4.1033 16.1556 4.10467 16.0268C4.10572 15.7677 4.20953 15.5196 4.39324 15.3371C4.57695 15.1546 4.82553 15.0527 5.08428 15.0537C5.34304 15.0548 5.59078 15.1587 5.773 15.3427C5.95522 15.5267 6.057 15.7756 6.05594 16.0348C6.05669 16.1637 6.03183 16.2914 5.98283 16.4106C5.93382 16.5298 5.86164 16.638 5.77048 16.729C5.67933 16.82 5.57101 16.8919 5.45184 16.9406C5.33267 16.9893 5.20503 17.0138 5.07633 17.0126Z"
          fill="#202020"
        />
        <path
          d="M23.9859 16.0451C23.9838 16.2376 23.9249 16.4252 23.8165 16.5842C23.7081 16.7433 23.5551 16.8666 23.3768 16.9386C23.1986 17.0107 23.003 17.0282 22.8147 16.989C22.6265 16.9499 22.4541 16.8558 22.3192 16.7186C22.1843 16.5814 22.093 16.4073 22.0568 16.2182C22.0206 16.0291 22.0412 15.8335 22.1159 15.6561C22.1906 15.4787 22.316 15.3274 22.4765 15.2213C22.6369 15.1152 22.8251 15.0591 23.0174 15.0601C23.1457 15.0606 23.2726 15.0865 23.3909 15.1364C23.5092 15.1863 23.6164 15.2591 23.7064 15.3507C23.7964 15.4422 23.8675 15.5507 23.9154 15.6699C23.9634 15.7891 23.9873 15.9166 23.9859 16.0451Z"
          fill="#202020"
        />
        <path
          d="M38.466 16.0395C38.4654 16.2331 38.4074 16.4221 38.2993 16.5826C38.1913 16.743 38.0381 16.8678 37.8591 16.9409C37.6802 17.0141 37.4836 17.0323 37.2943 16.9934C37.105 16.9545 36.9314 16.8602 36.7957 16.7224C36.66 16.5846 36.5683 16.4095 36.5321 16.2194C36.4958 16.0293 36.5168 15.8326 36.5923 15.6545C36.6679 15.4763 36.7945 15.3246 36.9563 15.2187C37.118 15.1127 37.3075 15.0572 37.5007 15.0593C37.7579 15.0633 38.0031 15.1682 38.1837 15.3516C38.3643 15.535 38.4657 15.782 38.466 16.0395Z"
          fill="#202020"
        />
        <path
          d="M13.2904 16.0467C13.3072 16.5249 12.8926 17.0142 12.3156 17.015C12.0616 17.0071 11.8206 16.9002 11.644 16.7172C11.4674 16.5342 11.369 16.2894 11.3697 16.0348C11.3704 15.7803 11.4702 15.5361 11.6479 15.3541C11.8256 15.1721 12.0671 15.0666 12.3212 15.0601C12.8615 15.0609 13.3143 15.5247 13.2904 16.0467Z"
          fill="#202020"
        />
        <path
          d="M19.4515 17.0126C19.3227 17.0138 19.1951 16.9893 19.0759 16.9404C18.9568 16.8916 18.8486 16.8195 18.7576 16.7283C18.6666 16.6371 18.5947 16.5287 18.546 16.4093C18.4974 16.29 18.473 16.1621 18.4742 16.0332C18.4767 15.7757 18.58 15.5294 18.7618 15.3473C18.9436 15.1652 19.1895 15.0618 19.4467 15.0593C19.5756 15.0583 19.7033 15.0831 19.8225 15.1321C19.9417 15.1812 20.05 15.2535 20.141 15.3449C20.2319 15.4363 20.3038 15.545 20.3524 15.6645C20.401 15.7841 20.4253 15.9121 20.4239 16.0411C20.426 16.1695 20.4022 16.2969 20.354 16.4159C20.3058 16.5348 20.2343 16.6429 20.1435 16.7335C20.0528 16.8242 19.9447 16.8956 19.8258 16.9436C19.7069 16.9915 19.5796 17.015 19.4515 17.0126Z"
          fill="#202020"
        />
        <path
          d="M8.65021 17.0126C8.52119 17.0152 8.39298 16.9917 8.27322 16.9436C8.15346 16.8955 8.04462 16.8237 7.95318 16.7325C7.86175 16.6413 7.78961 16.5326 7.74105 16.4128C7.6925 16.2931 7.66854 16.1648 7.67059 16.0356C7.67007 15.9076 7.69477 15.7808 7.74328 15.6625C7.79178 15.5441 7.86314 15.4365 7.95326 15.3458C8.04338 15.2551 8.15049 15.1831 8.26845 15.1339C8.38641 15.0848 8.5129 15.0594 8.64066 15.0593C8.89988 15.0605 9.14817 15.1641 9.33169 15.3474C9.51522 15.5308 9.61919 15.7792 9.62107 16.0388C9.62086 16.2967 9.51856 16.5441 9.33658 16.7266C9.1546 16.9091 8.90778 17.012 8.65021 17.0126Z"
          fill="#202020"
        />
        <path
          d="M32.9504 16.0363C32.9509 15.8434 33.0084 15.6549 33.1157 15.4947C33.223 15.3344 33.3752 15.2095 33.5533 15.1358C33.7313 15.0621 33.9272 15.0428 34.1161 15.0804C34.3051 15.118 34.4787 15.2107 34.6151 15.347C34.7515 15.4833 34.8446 15.6569 34.8826 15.8461C34.9205 16.0352 34.9018 16.2314 34.8286 16.4099C34.7554 16.5884 34.6311 16.7412 34.4713 16.849C34.3116 16.9568 34.1235 17.0149 33.9309 17.0158C33.6712 17.0158 33.4222 16.9127 33.2384 16.7291C33.0546 16.5455 32.9511 16.2963 32.9504 16.0363Z"
          fill="#202020"
        />
        <path
          d="M9.51048 19.6115C9.50365 19.837 9.4094 20.051 9.2477 20.2081C9.08599 20.3652 8.86956 20.4531 8.64426 20.4531C8.41897 20.4531 8.20253 20.3652 8.04083 20.2081C7.87913 20.051 7.78487 19.837 7.77805 19.6115C7.77453 19.4954 7.79434 19.3797 7.83629 19.2714C7.87824 19.1631 7.94148 19.0643 8.02226 18.9809C8.10304 18.8975 8.19972 18.8312 8.30657 18.7859C8.41341 18.7406 8.52825 18.7173 8.64426 18.7173C8.76028 18.7173 8.87511 18.7406 8.98196 18.7859C9.0888 18.8312 9.18548 18.8975 9.26626 18.9809C9.34705 19.0643 9.41029 19.1631 9.45224 19.2714C9.49419 19.3797 9.51399 19.4954 9.51048 19.6115Z"
          fill="#202020"
        />
        <path
          d="M18.5825 19.6011C18.5841 19.4296 18.6363 19.2624 18.7326 19.1206C18.8289 18.9788 18.9649 18.8687 19.1236 18.8042C19.2823 18.7397 19.4565 18.7237 19.6242 18.7582C19.792 18.7926 19.9458 18.8761 20.0663 18.9979C20.1868 19.1198 20.2685 19.2747 20.3013 19.443C20.334 19.6114 20.3163 19.7856 20.2503 19.9439C20.1843 20.1021 20.073 20.2373 19.9304 20.3323C19.7878 20.4273 19.6204 20.4779 19.4491 20.4778C19.3342 20.4792 19.2201 20.4574 19.1138 20.4138C19.0074 20.3701 18.9109 20.3054 18.83 20.2236C18.7492 20.1418 18.6856 20.0445 18.643 19.9376C18.6005 19.8306 18.5799 19.7162 18.5825 19.6011Z"
          fill="#202020"
        />
        <path
          d="M34.7903 19.6067C34.7908 19.7786 34.7403 19.9466 34.6453 20.0897C34.5503 20.2328 34.415 20.3444 34.2566 20.4104C34.0982 20.4764 33.9238 20.4938 33.7555 20.4605C33.5872 20.4272 33.4325 20.3445 33.3112 20.2231C33.1898 20.1016 33.1072 19.9469 33.0737 19.7783C33.0402 19.6098 33.0575 19.4351 33.1233 19.2764C33.189 19.1177 33.3003 18.9822 33.4431 18.8869C33.5859 18.7916 33.7537 18.7409 33.9253 18.7412C34.0392 18.7396 34.1524 18.7609 34.258 18.8038C34.3636 18.8467 34.4596 18.9104 34.5402 18.9911C34.6209 19.0718 34.6845 19.1678 34.7275 19.2736C34.7704 19.3793 34.7918 19.4926 34.7903 19.6067Z"
          fill="#202020"
        />
        <path
          d="M27.5534 19.6107C27.5534 19.7828 27.5024 19.951 27.4068 20.0941C27.3113 20.2371 27.1755 20.3486 27.0167 20.4143C26.8578 20.48 26.6831 20.497 26.5146 20.4632C26.3461 20.4294 26.1914 20.3463 26.0701 20.2244C25.9488 20.1025 25.8664 19.9472 25.8333 19.7784C25.8001 19.6095 25.8178 19.4346 25.884 19.2757C25.9502 19.1169 26.0619 18.9814 26.2051 18.8862C26.3483 18.791 26.5165 18.7406 26.6883 18.7412C26.8023 18.7408 26.9153 18.763 27.0206 18.8066C27.126 18.8502 27.2216 18.9143 27.3021 18.9951C27.3825 19.076 27.4462 19.172 27.4893 19.2777C27.5324 19.3834 27.5542 19.4965 27.5534 19.6107Z"
          fill="#202020"
        />
        <path
          d="M16.7514 19.6083C16.7517 19.7804 16.701 19.9487 16.6057 20.092C16.5104 20.2352 16.3748 20.3469 16.2161 20.4129C16.0574 20.4789 15.8827 20.4962 15.7142 20.4627C15.5456 20.4292 15.3908 20.3464 15.2692 20.2247C15.1477 20.103 15.065 19.9479 15.0316 19.7791C14.9981 19.6103 15.0154 19.4354 15.0813 19.2764C15.1473 19.1175 15.2588 18.9817 15.4018 18.8863C15.5448 18.7909 15.7129 18.7401 15.8847 18.7404C15.9988 18.7395 16.1119 18.7613 16.2175 18.8045C16.3231 18.8478 16.419 18.9117 16.4997 18.9925C16.5803 19.0733 16.6441 19.1693 16.6873 19.2751C16.7306 19.3808 16.7523 19.4941 16.7514 19.6083Z"
          fill="#202020"
        />
        <path
          d="M2.38418 19.6083C2.38449 19.7804 2.33382 19.9487 2.23858 20.0919C2.14333 20.2351 2.0078 20.3468 1.84914 20.4128C1.69048 20.4788 1.51582 20.4962 1.34728 20.4628C1.17873 20.4294 1.02389 20.3466 0.90234 20.225C0.780792 20.1034 0.698007 19.9484 0.664468 19.7796C0.630929 19.6109 0.648143 19.4359 0.713931 19.277C0.779718 19.118 0.891121 18.9822 1.03404 18.8867C1.17695 18.7912 1.34495 18.7403 1.51677 18.7404C1.63073 18.74 1.74365 18.7622 1.84902 18.8056C1.9544 18.8491 2.05015 18.913 2.13077 18.9936C2.21139 19.0743 2.27529 19.1701 2.31877 19.2756C2.36226 19.3811 2.38449 19.4942 2.38418 19.6083Z"
          fill="#202020"
        />
        <path
          d="M29.3837 19.6163C29.3827 19.4446 29.4326 19.2765 29.527 19.1332C29.6214 18.9899 29.7561 18.8778 29.914 18.8111C30.072 18.7444 30.2461 18.7261 30.4145 18.7584C30.5828 18.7907 30.7378 18.8723 30.8599 18.9928C30.982 19.1133 31.0657 19.2673 31.1005 19.4354C31.1352 19.6035 31.1195 19.7782 31.0552 19.9373C30.9909 20.0965 30.881 20.233 30.7393 20.3296C30.5976 20.4262 30.4305 20.4786 30.259 20.4802C30.1445 20.4825 30.0307 20.4619 29.9243 20.4195C29.8178 20.377 29.721 20.3137 29.6394 20.2332C29.5578 20.1526 29.4931 20.0566 29.4492 19.9506C29.4053 19.8447 29.383 19.731 29.3837 19.6163Z"
          fill="#202020"
        />
        <path
          d="M36.6254 19.6091C36.6237 19.495 36.6448 19.3818 36.6874 19.276C36.73 19.1702 36.7934 19.074 36.8737 18.993C36.954 18.9121 37.0496 18.8481 37.155 18.8047C37.2603 18.7613 37.3733 18.7395 37.4872 18.7404C37.6035 18.7369 37.7193 18.7568 37.8278 18.7989C37.9362 18.841 38.0351 18.9046 38.1186 18.9857C38.2021 19.0668 38.2685 19.1639 38.3138 19.2712C38.3592 19.3785 38.3825 19.4938 38.3825 19.6103C38.3825 19.7268 38.3592 19.8421 38.3138 19.9494C38.2685 20.0567 38.2021 20.1538 38.1186 20.2349C38.0351 20.316 37.9362 20.3795 37.8278 20.4217C37.7193 20.4638 37.6035 20.4837 37.4872 20.4802C37.373 20.4816 37.2596 20.46 37.1539 20.4166C37.0482 20.3732 36.9522 20.309 36.8718 20.2277C36.7914 20.1464 36.7281 20.0498 36.6858 19.9435C36.6435 19.8372 36.6229 19.7235 36.6254 19.6091Z"
          fill="#202020"
        />
        <path
          d="M22.1508 19.6067C22.149 19.4923 22.1701 19.3788 22.2131 19.2728C22.256 19.1668 22.3198 19.0705 22.4007 18.9898C22.4815 18.909 22.5778 18.8454 22.6838 18.8027C22.7898 18.76 22.9032 18.7391 23.0174 18.7412C23.1336 18.7383 23.2491 18.7587 23.3573 18.8013C23.4654 18.8439 23.5639 18.9078 23.6469 18.9893C23.7299 19.0707 23.7958 19.168 23.8406 19.2754C23.8853 19.3827 23.9081 19.498 23.9076 19.6144C23.9071 19.7308 23.8832 19.8459 23.8375 19.9528C23.7917 20.0598 23.725 20.1565 23.6412 20.2371C23.5574 20.3178 23.4584 20.3808 23.3499 20.4224C23.2413 20.464 23.1256 20.4834 23.0094 20.4794C22.8952 20.4809 22.7818 20.4593 22.6761 20.4158C22.5703 20.3724 22.4745 20.3079 22.3943 20.2264C22.3141 20.1449 22.2512 20.048 22.2093 19.9415C22.1675 19.835 22.1476 19.7211 22.1508 19.6067Z"
          fill="#202020"
        />
        <path
          d="M4.21449 19.6107C4.21481 19.439 4.26598 19.2712 4.36154 19.1286C4.4571 18.9861 4.59274 18.8751 4.75131 18.8097C4.90987 18.7444 5.08423 18.7276 5.25232 18.7615C5.4204 18.7954 5.57467 18.8786 5.69558 19.0003C5.81649 19.1221 5.89861 19.277 5.93156 19.4455C5.96451 19.614 5.94681 19.7886 5.88068 19.947C5.81455 20.1054 5.70298 20.2407 5.56008 20.3356C5.41719 20.4305 5.24939 20.4808 5.07792 20.4802C4.96389 20.481 4.85084 20.4591 4.74538 20.4156C4.63992 20.3722 4.54419 20.3081 4.46378 20.2271C4.38336 20.1462 4.3199 20.0499 4.27709 19.9441C4.23429 19.8382 4.213 19.7249 4.21449 19.6107Z"
          fill="#202020"
        />
        <path
          d="M11.4554 19.7071C11.458 19.5361 11.5112 19.3696 11.6081 19.2288C11.705 19.0879 11.8414 18.9788 12 18.9154C12.1587 18.852 12.3325 18.837 12.4997 18.8723C12.6668 18.9076 12.8198 18.9916 12.9394 19.1138C13.059 19.236 13.1398 19.3908 13.1717 19.5589C13.2036 19.727 13.1851 19.9007 13.1185 20.0583C13.052 20.2159 12.9404 20.3503 12.7979 20.4445C12.6553 20.5387 12.488 20.5885 12.3172 20.5878C12.2025 20.588 12.0888 20.5653 11.983 20.5209C11.8772 20.4765 11.7813 20.4113 11.701 20.3293C11.6207 20.2472 11.5576 20.1499 11.5154 20.0431C11.4732 19.9362 11.4528 19.822 11.4554 19.7071Z"
          fill="#202020"
        />
        <path
          d="M8.64304 23.9438C8.49217 23.9432 8.34485 23.8978 8.21987 23.8131C8.09489 23.7285 7.99789 23.6085 7.94124 23.4685C7.88458 23.3284 7.87083 23.1747 7.90174 23.0268C7.93264 22.8789 8.00681 22.7435 8.11478 22.638C8.22276 22.5325 8.35966 22.4615 8.50804 22.4341C8.65643 22.4068 8.80959 22.4243 8.94801 22.4844C9.08642 22.5445 9.20384 22.6446 9.2853 22.7718C9.36676 22.8989 9.40857 23.0475 9.40541 23.1986C9.40227 23.3984 9.32036 23.5889 9.17754 23.7285C9.03473 23.8681 8.8426 23.9455 8.64304 23.9438Z"
          fill="#202020"
        />
        <path
          d="M1.52076 23.9437C1.3192 23.9452 1.12531 23.8665 0.981739 23.7248C0.838171 23.5831 0.756686 23.3901 0.755208 23.1882C0.753731 22.9864 0.832382 22.7922 0.973861 22.6484C1.11534 22.5046 1.30806 22.423 1.50962 22.4216C1.71118 22.4201 1.90506 22.4988 2.04863 22.6405C2.1922 22.7822 2.27369 22.9752 2.27516 23.1771C2.27664 23.3789 2.19799 23.5731 2.05651 23.7169C1.91503 23.8607 1.72232 23.9423 1.52076 23.9437Z"
          fill="#202020"
        />
        <path
          d="M5.07476 23.9437C4.92438 23.9428 4.77767 23.8972 4.65319 23.8127C4.52871 23.7282 4.43206 23.6086 4.37546 23.4691C4.31886 23.3296 4.30487 23.1764 4.33525 23.0289C4.36563 22.8814 4.43902 22.7463 4.54613 22.6406C4.65324 22.5349 4.78925 22.4633 4.93696 22.4351C5.08466 22.4068 5.23742 22.4231 5.37589 22.4818C5.51436 22.5405 5.63233 22.6391 5.71486 22.765C5.79739 22.8909 5.84077 23.0384 5.83951 23.189C5.84017 23.2893 5.82076 23.3886 5.78242 23.4812C5.74408 23.5738 5.68759 23.6578 5.6163 23.7281C5.545 23.7985 5.46034 23.8538 5.36733 23.8909C5.27431 23.9279 5.17483 23.9459 5.07476 23.9437Z"
          fill="#202020"
        />
        <path
          d="M22.2566 23.1691C22.2598 23.0189 22.3073 22.8731 22.3933 22.75C22.4792 22.6269 22.5997 22.5321 22.7394 22.4776C22.8792 22.423 23.0319 22.4112 23.1784 22.4436C23.3248 22.476 23.4584 22.5511 23.5622 22.6596C23.666 22.768 23.7354 22.9048 23.7616 23.0527C23.7877 23.2006 23.7695 23.3529 23.7093 23.4904C23.649 23.628 23.5493 23.7445 23.4229 23.8254C23.2965 23.9062 23.149 23.9477 22.9991 23.9446C22.8991 23.9438 22.8003 23.9229 22.7085 23.8833C22.6167 23.8436 22.5337 23.786 22.4645 23.7137C22.3953 23.6414 22.3413 23.556 22.3055 23.4625C22.2698 23.3689 22.2532 23.2692 22.2566 23.1691Z"
          fill="#202020"
        />
        <path
          d="M30.2535 23.9438C30.1031 23.9442 29.9559 23.9 29.8306 23.8166C29.7054 23.7333 29.6076 23.6146 29.5497 23.4756C29.4918 23.3365 29.4764 23.1834 29.5055 23.0357C29.5345 22.8879 29.6067 22.752 29.7129 22.6453C29.8191 22.5387 29.9545 22.4659 30.102 22.4364C30.2495 22.4068 30.4024 22.4217 30.5414 22.4793C30.6804 22.5368 30.7992 22.6344 30.8829 22.7596C30.9665 22.8848 31.0111 23.032 31.0111 23.1827C31.0117 23.2826 30.9926 23.3816 30.9548 23.4741C30.917 23.5666 30.8612 23.6506 30.7908 23.7214C30.7203 23.7922 30.6366 23.8483 30.5444 23.8864C30.4521 23.9246 30.3533 23.9441 30.2535 23.9438Z"
          fill="#202020"
        />
        <path
          d="M34.6821 23.1922C34.6825 23.2912 34.6633 23.3892 34.6257 23.4807C34.588 23.5722 34.5326 23.6552 34.4626 23.7251C34.3927 23.795 34.3096 23.8503 34.2182 23.8879C34.1268 23.9254 34.0288 23.9444 33.93 23.9437C33.779 23.9453 33.6309 23.9018 33.5047 23.8187C33.3785 23.7356 33.2798 23.6167 33.2214 23.4772C33.1629 23.3377 33.1473 23.184 33.1765 23.0356C33.2057 22.8871 33.2784 22.7508 33.3853 22.6439C33.4923 22.5371 33.6285 22.4646 33.7768 22.4356C33.9251 22.4067 34.0786 22.4227 34.2177 22.4815C34.3569 22.5404 34.4754 22.6394 34.5581 22.766C34.6408 22.8926 34.684 23.041 34.6821 23.1922Z"
          fill="#202020"
        />
        <path
          d="M36.7352 23.1835C36.7285 23.0799 36.743 22.9761 36.778 22.8784C36.8129 22.7807 36.8675 22.6912 36.9384 22.6155C37.0093 22.5398 37.0949 22.4794 37.1899 22.4382C37.285 22.3969 37.3876 22.3756 37.4912 22.3756C37.5948 22.3756 37.6973 22.3969 37.7924 22.4382C37.8875 22.4794 37.9731 22.5398 38.044 22.6155C38.1148 22.6912 38.1694 22.7807 38.2044 22.8784C38.2393 22.9761 38.2539 23.0799 38.2472 23.1835C38.2539 23.287 38.2393 23.3909 38.2044 23.4885C38.1694 23.5862 38.1148 23.6757 38.044 23.7514C37.9731 23.8272 37.8875 23.8875 37.7924 23.9288C37.6973 23.97 37.5948 23.9913 37.4912 23.9913C37.3876 23.9913 37.285 23.97 37.1899 23.9288C37.0949 23.8875 37.0093 23.8272 36.9384 23.7514C36.8675 23.6757 36.8129 23.5862 36.778 23.4885C36.743 23.3909 36.7285 23.287 36.7352 23.1835Z"
          fill="#202020"
        />
        <path
          d="M16.6383 23.1851C16.6374 23.3349 16.5921 23.4811 16.5082 23.6052C16.4243 23.7293 16.3055 23.8257 16.1669 23.8822C16.0283 23.9387 15.8761 23.9528 15.7295 23.9227C15.5829 23.8926 15.4485 23.8197 15.3433 23.7131C15.2381 23.6065 15.1668 23.4711 15.1385 23.3239C15.1101 23.1768 15.1259 23.0245 15.184 22.8864C15.242 22.7482 15.3396 22.6304 15.4645 22.5478C15.5894 22.4652 15.7359 22.4216 15.8855 22.4224C15.9852 22.4219 16.084 22.4414 16.1761 22.4797C16.2683 22.518 16.3518 22.5743 16.4219 22.6453C16.492 22.7164 16.5473 22.8007 16.5844 22.8934C16.6216 22.986 16.6399 23.0852 16.6383 23.1851Z"
          fill="#202020"
        />
        <path
          d="M20.2066 23.1787C20.2074 23.3291 20.1637 23.4763 20.0809 23.6018C19.9981 23.7273 19.88 23.8254 19.7415 23.8836C19.603 23.9419 19.4504 23.9577 19.303 23.9291C19.1555 23.9004 19.0199 23.8287 18.9132 23.7228C18.8065 23.617 18.7335 23.4818 18.7036 23.3344C18.6736 23.187 18.6879 23.0341 18.7448 22.8948C18.8016 22.7556 18.8985 22.6364 19.023 22.5523C19.1475 22.4682 19.2941 22.423 19.4443 22.4224C19.5441 22.4214 19.643 22.4403 19.7355 22.4779C19.8279 22.5154 19.912 22.571 19.9829 22.6413C20.0538 22.7117 20.1101 22.7954 20.1485 22.8876C20.1869 22.9798 20.2067 23.0788 20.2066 23.1787Z"
          fill="#202020"
        />
        <path
          d="M2.11125 26.8678C2.1121 26.9463 2.09729 27.0242 2.06767 27.0969C2.03806 27.1696 1.99425 27.2357 1.9388 27.2912C1.88336 27.3467 1.8174 27.3906 1.7448 27.4203C1.67219 27.4499 1.59441 27.4647 1.516 27.4639C1.43394 27.4696 1.35159 27.4582 1.2741 27.4306C1.19661 27.403 1.12564 27.3596 1.06562 27.3033C1.0056 27.247 0.957821 27.1788 0.925266 27.1032C0.892711 27.0275 0.87608 26.946 0.876409 26.8636C0.876738 26.7812 0.894022 26.6998 0.92718 26.6244C0.960338 26.549 1.00866 26.4813 1.06913 26.4254C1.12959 26.3696 1.20091 26.3268 1.27862 26.2998C1.35633 26.2728 1.43876 26.2621 1.52077 26.2685C1.59908 26.2681 1.67667 26.2835 1.74898 26.3136C1.82129 26.3437 1.88687 26.3879 1.94187 26.4438C1.99687 26.4996 2.04018 26.5658 2.06927 26.6386C2.09835 26.7115 2.11263 26.7894 2.11125 26.8678Z"
          fill="#202020"
        />
        <path
          d="M4.48748 26.8646C4.48577 26.7861 4.49982 26.708 4.52879 26.6351C4.55776 26.5621 4.60106 26.4957 4.65612 26.4398C4.71119 26.3839 4.7769 26.3397 4.84936 26.3097C4.92182 26.2797 4.99955 26.2646 5.07795 26.2653C5.16 26.2593 5.2424 26.2703 5.32 26.2976C5.39761 26.3249 5.46876 26.3679 5.52901 26.424C5.58927 26.4801 5.63732 26.5481 5.67018 26.6236C5.70304 26.6991 5.72 26.7806 5.72 26.863C5.72 26.9454 5.70304 27.0269 5.67018 27.1024C5.63732 27.1779 5.58927 27.2458 5.52901 27.3019C5.46876 27.358 5.39761 27.4011 5.32 27.4284C5.2424 27.4557 5.16 27.4667 5.07795 27.4607C4.99949 27.4626 4.92147 27.4484 4.84866 27.4191C4.77586 27.3897 4.7098 27.3458 4.65453 27.29C4.59925 27.2342 4.55593 27.1677 4.52721 27.0945C4.49849 27.0214 4.48497 26.9431 4.48748 26.8646Z"
          fill="#202020"
        />
        <path
          d="M36.8936 26.8598C36.8939 26.7815 36.9096 26.704 36.94 26.6317C36.9703 26.5595 37.0146 26.494 37.0703 26.439C37.1259 26.3839 37.1919 26.3405 37.2644 26.3111C37.337 26.2817 37.4146 26.2669 37.4928 26.2677C37.5749 26.2624 37.6571 26.274 37.7345 26.302C37.8119 26.33 37.8826 26.3736 37.9424 26.4302C38.0022 26.4867 38.0497 26.555 38.0819 26.6308C38.1141 26.7066 38.1304 26.7882 38.1298 26.8705C38.1291 26.9529 38.1115 27.0342 38.0781 27.1095C38.0446 27.1847 37.9961 27.2522 37.9354 27.3078C37.8747 27.3634 37.8033 27.4059 37.7255 27.4326C37.6477 27.4594 37.5652 27.4697 37.4832 27.4631C37.4049 27.4626 37.3274 27.4465 37.2552 27.4159C37.1831 27.3852 37.1177 27.3406 37.0629 27.2845C37.0081 27.2284 36.9649 27.162 36.9358 27.0891C36.9068 27.0162 36.8924 26.9383 36.8936 26.8598Z"
          fill="#202020"
        />
        <path
          d="M19.4546 27.4448C19.3737 27.4493 19.2927 27.4372 19.2165 27.4094C19.1403 27.3815 19.0707 27.3385 19.0116 27.2828C18.9526 27.2271 18.9056 27.16 18.8733 27.0855C18.841 27.011 18.8242 26.9308 18.8239 26.8496C18.8235 26.7684 18.8397 26.688 18.8714 26.6132C18.9031 26.5385 18.9496 26.471 19.0082 26.4149C19.0667 26.3587 19.1361 26.3151 19.212 26.2866C19.2879 26.2582 19.3689 26.2455 19.4498 26.2493C19.6029 26.2566 19.7472 26.3224 19.8532 26.4333C19.9591 26.5441 20.0185 26.6914 20.0191 26.8448C20.0197 26.9982 19.9615 27.146 19.8565 27.2576C19.7514 27.3693 19.6076 27.4363 19.4546 27.4448Z"
          fill="#202020"
        />
        <path
          d="M29.659 26.8885C29.6572 26.8101 29.6711 26.7322 29.6997 26.6592C29.7283 26.5862 29.7712 26.5197 29.8258 26.4636C29.8804 26.4074 29.9457 26.3627 30.0177 26.3321C30.0898 26.3014 30.1672 26.2855 30.2455 26.2852C30.3264 26.2805 30.4075 26.2924 30.4837 26.32C30.5599 26.3477 30.6297 26.3906 30.6888 26.4461C30.748 26.5016 30.7952 26.5686 30.8277 26.643C30.8601 26.7173 30.8772 26.7976 30.8777 26.8787C30.8782 26.9599 30.8623 27.0403 30.8308 27.1152C30.7993 27.19 30.753 27.2576 30.6946 27.3139C30.6362 27.3702 30.567 27.414 30.4912 27.4427C30.4153 27.4713 30.3345 27.4843 30.2535 27.4806C30.1752 27.4823 30.0975 27.4681 30.0248 27.4389C29.9522 27.4098 29.8862 27.3663 29.8307 27.311C29.7752 27.2557 29.7314 27.1898 29.7019 27.1173C29.6724 27.0447 29.6578 26.9669 29.659 26.8885Z"
          fill="#202020"
        />
        <path
          d="M34.5237 26.8933C34.5219 27.0119 34.4851 27.1274 34.4177 27.225C34.3504 27.3227 34.2556 27.3981 34.1454 27.4417C34.0352 27.4854 33.9146 27.4953 33.7988 27.4702C33.683 27.4451 33.5772 27.3861 33.4949 27.3008C33.4126 27.2154 33.3574 27.1075 33.3364 26.9908C33.3154 26.874 33.3294 26.7536 33.3768 26.6448C33.4242 26.536 33.5027 26.4438 33.6025 26.3798C33.7023 26.3158 33.8187 26.2829 33.9372 26.2852C34.0157 26.2862 34.0932 26.3028 34.1652 26.334C34.2373 26.3652 34.3024 26.4104 34.357 26.4669C34.4115 26.5234 34.4543 26.5902 34.4829 26.6634C34.5115 26.7366 34.5254 26.8147 34.5237 26.8933Z"
          fill="#202020"
        />
        <path
          d="M8.64224 27.4631C8.56368 27.4632 8.48587 27.4477 8.41334 27.4175C8.3408 27.3872 8.27498 27.3429 8.21968 27.287C8.16439 27.2311 8.12072 27.1648 8.09121 27.0919C8.06171 27.0189 8.04695 26.9409 8.04779 26.8622C8.04412 26.7811 8.05697 26.7001 8.08554 26.6241C8.11412 26.5482 8.15783 26.4788 8.21401 26.4203C8.27019 26.3618 8.33767 26.3153 8.41235 26.2837C8.48703 26.2522 8.56734 26.2361 8.6484 26.2366C8.72946 26.2371 8.80958 26.2541 8.88387 26.2866C8.95816 26.319 9.02509 26.3663 9.08056 26.4255C9.13604 26.4847 9.17891 26.5546 9.20658 26.6309C9.23424 26.7072 9.24611 26.7883 9.24147 26.8694C9.24001 27.0275 9.1762 27.1786 9.06396 27.2898C8.95172 27.401 8.80014 27.4633 8.64224 27.4631Z"
          fill="#202020"
        />
        <path
          d="M2.05554 30.4373C2.05522 30.5449 2.02297 30.65 1.96291 30.7392C1.90285 30.8283 1.81768 30.8977 1.71821 30.9383C1.61874 30.9789 1.50946 30.989 1.40425 30.9673C1.29904 30.9456 1.20265 30.893 1.12731 30.8163C1.05197 30.7397 1.00109 30.6423 0.981124 30.5366C0.961158 30.4309 0.973008 30.3216 1.01517 30.2227C1.05734 30.1237 1.12791 30.0396 1.21793 29.9809C1.30795 29.9222 1.41336 29.8916 1.52077 29.893C1.59168 29.8932 1.66184 29.9075 1.7272 29.935C1.79257 29.9626 1.85183 30.0028 1.90156 30.0534C1.95129 30.1041 1.99051 30.1641 2.01694 30.23C2.04337 30.2958 2.05649 30.3663 2.05554 30.4373Z"
          fill="#202020"
        />
        <path
          d="M8.63906 30.9793C8.56816 30.9797 8.4979 30.966 8.43237 30.9389C8.36684 30.9118 8.30736 30.8719 8.25742 30.8215C8.20747 30.7711 8.16807 30.7113 8.1415 30.6454C8.11493 30.5796 8.10174 30.5091 8.10269 30.4382C8.09989 30.3649 8.11189 30.2918 8.13796 30.2232C8.16404 30.1547 8.20365 30.0921 8.25444 30.0393C8.30522 29.9865 8.36613 29.9444 8.43352 29.9157C8.5009 29.887 8.57338 29.8722 8.64662 29.8722C8.71985 29.8722 8.79233 29.887 8.85971 29.9157C8.9271 29.9444 8.98801 29.9865 9.0388 30.0393C9.08958 30.0921 9.12919 30.1547 9.15527 30.2232C9.18134 30.2918 9.19334 30.3649 9.19054 30.4382C9.19155 30.5104 9.17789 30.5822 9.15039 30.649C9.12289 30.7159 9.08212 30.7764 9.03056 30.827C8.97899 30.8776 8.9177 30.9172 8.85041 30.9434C8.78311 30.9696 8.71121 30.9818 8.63906 30.9793Z"
          fill="#202020"
        />
        <path
          d="M37.488 30.9793C37.416 30.98 37.3445 30.9663 37.2779 30.9388C37.2113 30.9113 37.1508 30.8706 37.1003 30.8192C37.0497 30.7678 37.01 30.7067 36.9835 30.6396C36.957 30.5725 36.9443 30.5007 36.9461 30.4286C36.9474 30.3211 36.9803 30.2165 37.0409 30.1278C37.1014 30.039 37.1869 29.9703 37.2864 29.9301C37.3859 29.8899 37.495 29.8801 37.6001 29.902C37.7051 29.9238 37.8014 29.9763 37.8767 30.0529C37.952 30.1294 38.003 30.2265 38.0233 30.3321C38.0436 30.4376 38.0322 30.5467 37.9907 30.6458C37.9491 30.7449 37.8792 30.8294 37.7897 30.8887C37.7003 30.9481 37.5953 30.9796 37.488 30.9793Z"
          fill="#202020"
        />
        <path
          d="M5.07716 30.9793C5.00632 30.9797 4.93611 30.9661 4.87056 30.9392C4.80501 30.9123 4.74544 30.8726 4.69527 30.8225C4.64511 30.7724 4.60535 30.7129 4.57829 30.6473C4.55123 30.5818 4.5374 30.5115 4.53762 30.4406C4.53534 30.3674 4.54783 30.2945 4.57434 30.2263C4.60084 30.158 4.64082 30.0959 4.69187 30.0435C4.74292 29.9911 4.804 29.9495 4.87146 29.9213C4.93892 29.8931 5.01136 29.8788 5.08446 29.8793C5.15756 29.8798 5.22981 29.895 5.29689 29.9241C5.36397 29.9532 5.4245 29.9956 5.47486 30.0486C5.52522 30.1017 5.56437 30.1644 5.58997 30.233C5.61558 30.3015 5.6271 30.3746 5.62387 30.4477C5.62336 30.5187 5.60875 30.5889 5.5809 30.6542C5.55305 30.7195 5.5125 30.7787 5.46163 30.8281C5.41075 30.8776 5.35056 30.9164 5.28455 30.9424C5.21855 30.9683 5.14805 30.9809 5.07716 30.9793Z"
          fill="#202020"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_240">
          <rect width="39" height="31" fill="white" />
        </clipPath>
      </defs>
    </Box>
  );
}
