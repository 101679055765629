import { useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import Privacy from "../pages/Privacy";
import Eula from "../pages/Eula";
import Terms from "../pages/Terms";
import DeRegister from "../pages/DeRegister";

export default function Router() {
  return useRoutes([
    {
      id: "root",
      path: "/",
      Component: Home,
    },
    {
      id: "privacy",
      path: "/privacy-policy",
      Component: Privacy,
    },
    {
      id: "terms",
      path: "/terms-conditions",
      Component: Terms,
    },
    {
      id: "eula",
      path: "/end-user-licence-agreement",
      Component: Eula,
    },
    {
      id: "delete-account-request",
      path: "/delete-account-request",
      Component: DeRegister,
    },
  ]);
}
