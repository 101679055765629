import { Box, Link } from "@mui/material";
import { Logo } from "../assets";
import RouterLink from "../router/RouterLink";

export default function Header() {
  return (
    <Box
      sx={{
        height: 110,
        background: "#EDE6DC",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        position: "fixed",
      }}
    >
      <Box maxWidth={1324} sx={{ margin: "auto", width: "100%" }}>
        <Link
          href={"/"}
          component={RouterLink}
          mx={{ xs: "20px", md: 0 }}
          sx={{
            width: 73,
            height: 73,
            marginY: "17px",
            background: "#F8F8F0",
            borderRadius: "20px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Logo />
        </Link>
      </Box>
    </Box>
  );
}
