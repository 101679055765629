import { Box, Button, Stack, TextField } from "@mui/material";
import { useState } from "react";

export default function DeRegister() {
  const handleSubmit = () => {
    setTimeout(() => {
      alert(
        "Your request for deleting account has been received, we'll process it in the next 7-14 business day"
      );
    }, 1000);
  };
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 270px)",
        width: "100%",
        background: "#EDE6DC",
      }}
    >
      <Stack
        spacing={3}
        maxWidth={750}
        width={"100%"}
        textAlign={"center"}
        sx={{ margin: "auto", width: "100%" }}
      >
        <Box sx={{ fontSize: 32, fontWeight: 600, margin: "auto" }}>
          Delete Account Request
        </Box>
        <TextField
          sx={{
            width: "100%",
            background: "white",
            padding: "4px",
            borderRadius: 1,
          }}
          label={"First name"}
          variant="standard"
        />
        <TextField
          sx={{
            width: "100%",
            background: "white",
            padding: "4px",
            borderRadius: 1,
          }}
          label={"Last name"}
          variant="standard"
        />
        <TextField
          sx={{
            width: "100%",
            background: "white",
            padding: "4px",
            borderRadius: 1,
          }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          label={"Username*"}
          variant="standard"
        />
        <TextField
          sx={{
            width: "100%",
            background: "white",
            padding: "4px",
            borderRadius: 1,
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label={"Email*"}
          variant="standard"
        />
        <TextField
          sx={{
            width: "100%",
            background: "white",
            padding: "4px",
            borderRadius: 1,
          }}
          label={"Reason for deleting your account"}
          variant="standard"
        />
        <Button
          variant="contained"
          disabled={!username}
          onClick={() => handleSubmit()}
          sx={{
            background: "black",
            color: "white",
            width: "200px",
            alignSelf: "center",
          }}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
}
