import { Box } from "@mui/material";

export default function Terms() {
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 270px)",
        width: "100%",
        background: "#EDE6DC",
      }}
    >
      <Box maxWidth={1324} sx={{ margin: "auto", width: "100%" }}>
        <Box py={2} px={{ xs: "20px", md: 0 }}>
          <h1>Website Terms and Conditions</h1>
          <p>1. Introduction</p>
          <p>1.1 Welcome</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Welcome to our website! These Terms and Conditions (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Terms</strong>
            <span style={{ backgroundColor: "initial" }}>
              ) apply to the Muse website located at&nbsp;
            </span>
            <a
              href="https://www.jointhemuse.com/"
              target="_blank"
              style={{ color: "rgb(48, 48, 48)" }}
            >
              www.jointhemuse.com
            </a>
            <span style={{ backgroundColor: "initial" }}>&nbsp;(</span>
            <strong style={{ backgroundColor: "initial" }}>Website</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). The Website is owned and operated by Muse Technology Pty Ltd
              (ACN 649 320 178) (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Muse</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). In these terms ‘us’, ‘we’ and ‘our’ means Muse and any of its
              related bodies corporate.
            </span>
          </p>
          <p>1.2 Agreement</p>
          <p>
            Please read these Terms carefully. Understanding these Terms is
            important because, by using the Website or any of our related
            services, you’re agreeing to these Terms. If you don’t agree to the
            terms and conditions contained in these Terms, then please do not
            use the Website or any of our related services.
          </p>
          <p>2. Content</p>
          <p>2.1 Our content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Unless otherwise indicated, we own, control or license all rights,
              title and interest (including copyright, designs, patents,
              trademarks and other intellectual property rights) in the Website
              and in all of the material (including all text, graphics,
              photographs, artwork, visual interfaces, user interfaces, logos,
              audio, software and computer code made available on the Website
              (collectively,&nbsp;
            </span>
            <strong style={{ backgroundColor: "initial" }}>Content</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). Content includes, but is not limited to, the design, structure,
              selection, coordination, expression, “look and feel” and
              arrangement of such Content, contained on the Website.
            </span>
          </p>
          <p>2.2 Third-party content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We may integrate Content or applications from third-parties with
              the Website and make some or all of them available to you. The
              views expressed in third-party Content or applications are theirs,
              and don’t necessarily reflect our views. Any Content or
              applications from third-parties are provided on the Website on an
              ‘as-is’ basis and we make no express or implied representations or
              warranties about that Content or those applications. Content or
              applications from third-parties are subject to change or
              discontinuance on the Website without notice.
            </span>
          </p>
          <p>3. Use of the Website</p>
          <p>3.1 Limited Access</p>
          <p>
            The Website is made available on a limited access basis, and no
            ownership right is conveyed to you through your use or access of the
            Website.
          </p>
          <p>3.2 Your responsibilities</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Whilst you use the&nbsp;Website, you agree that you will:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                comply with your obligations under these Terms;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                comply with all Federal and State laws, rules and regulations
                related to or connected with your use of the Website.
              </span>
            </li>
          </ul>
          <p>3.3 Prohibited activity</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Unless expressly stated otherwise in these Terms, you agree that
              you will not do any of the following:&nbsp;
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Website to store or transmit infringing, libelous or
                otherwise unlawful or tortious material or inappropriate
                communications or content, or to store or transmit material in
                violation of third-party privacy rights.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Using the Website for any wrongful activities including, but not
                limited to, using any related service to stalk, harass, threaten
                or violate the privacy of others.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Website to store or transmit malicious code including
                code, files, scripts, agents or programs intended to do harm,
                including, for example, viruses, worms, time bombs and Trojan
                horses.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Interfere with or disrupt the integrity or performance of the
                Website.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Modify or create derivative works based on the Website or any
                part, feature, function or user interface thereof.&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Copy any content on the Website including using “deep-link”,
                “page-scrape”, “robot”, “spider” or other automatic device,
                program, algorithm or methodology, or any similar or equivalent
                manual process, to access, acquire, copy or monitor any portion
                of the Website or any Content, or in any way reproduce or
                circumvent the navigational structure or presentation of the
                Site or any Content, to obtain or attempt to obtain any
                materials, documents or information through any means not
                purposely made available through the Website.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Disassemble, reverse engineer, or decompile any part of the
                Website or access it to&nbsp;
              </span>
            </li>
            <li className="ql-indent-1">
              <span style={{ backgroundColor: "initial" }}>
                build a competitive product or service;
              </span>
            </li>
            <li className="ql-indent-1">
              <span style={{ backgroundColor: "initial" }}>
                build a product or service using similar ideas, features,
                functions or graphics of the Website;
              </span>
            </li>
            <li className="ql-indent-1">
              <span style={{ backgroundColor: "initial" }}>
                copy any ideas, features, functions or graphics of the
                Website.&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Determine whether any ideas, features, functions or graphics of
                the Website are within the scope of any patent.
              </span>
            </li>
          </ul>
          <p>4. Links to other sites</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The Website may contain links to other independent third-party
              websites (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Linked Sites</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). These Linked Sites are provided solely as a convenience to you.
              Such Linked Sites are not under our control, and we are not
              responsible for and do not endorse the content of such Linked
              Sites, including any information or materials contained on such
              Linked Sites. You will need to make your own independent judgment
              regarding your interaction with these Linked Sites.
            </span>
          </p>
          <p>5. Disclaimer</p>
          <p>5.1 No promise</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We provide the Website to you using a commercially reasonable
              level of care. However, there are certain things that we don’t
              promise about the Website.
            </span>
          </p>
          <p>5.2 No warranties</p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, WE DO NOT MAKE
                ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
                OTHERWISE, AND WE SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES,
                INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT
                PERMITTED BY APPLICABLE LAW. THE WEBSITE IS PROVIDED ‘AS IS’.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                WE DO NOT WARRANT THAT THE WEBSITE OR THE CONTENT WILL BE
                COMPLETE, ACCURATE OR UP-TO-DATE. ALL INFORMATION PROVIDED ON
                THE WEBSITE IS SUBJECT TO CHANGE WITHOUT NOTICE.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                WE DO NOT WARRANT THAT OUR SECURITY PROCEDURES WILL BE
                ERROR-FREE OR THAT YOUR DATA WILL ALWAYS BE SECURE IN
                TRANSMISSION.
              </span>
            </li>
          </ul>
          <p>5.3 Your statutory guarantees</p>
          <p>
            You have statutory guarantees under the Australian Consumer Law and
            nothing in these Terms is intended to affect such rights.
          </p>
          <p>6. Limitation of liability</p>
          <p>
            To the maximum extent permitted by law, including the Australian
            Consumer Law, in no event shall we be liable for any direct and
            indirect loss, damage or expense (irrespective of the manner in
            which it occurs) which may be suffered due to your use of the
            Website and/or the information or materials contained on it, or as a
            result of the inaccessibility of the Website and/or the fact that
            certain information or materials contained on it are incorrect,
            incomplete or not up-to-date.
          </p>
          <p>7. Indemnity</p>
          <p>
            You agree to indemnify and hold us and our officers, directors,
            employees, agents, subsidiaries and affiliates, harmless from any
            demands, loss, liability, claims or expenses (including legal fees),
            made against us by any third party due to or arising out of or in
            connection with your use of the Website.
          </p>
          <p>8. Feedback</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Occasionally, you may provide us with feedback on our Website (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Feedback</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). We may in connection with any of our products or services
              freely use, copy, disclose, license, distribute and exploit any
              Feedback in any manner without any obligation, royalty or
              restriction based on intellectual property rights or otherwise.
              Nothing in these Terms limit our right to independently use,
              develop, evaluate, or market products or services, whether
              incorporating Feedback or otherwise.
            </span>
          </p>
          <p>9. Privacy</p>
          <p>9.1 Collection</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We&nbsp;collect certain data and information about you in
              connection with your use of the Website and otherwise in
              connection with these Terms. We collect and use all personal
              information in such data and information in accordance with
              applicable privacy laws and our privacy policy, which you
              acknowledge.
            </span>
          </p>
          <p>9.2 Disclosure</p>
          <p>
            We may disclose that information to third parties that help us
            deliver our services (including information technology suppliers,
            communication suppliers and our business partners) or as required by
            law. If you do not provide this information, we may not be able to
            provide all of our goods or services to you.
          </p>
          <p>9.3 Consent</p>
          <p>
            By providing your personal information to us, you consent to the
            collection, use, storage and disclosure of that personal information
            as described in our privacy policy and these Terms.
          </p>
          <p>10. Others terms and conditions</p>
          <p>10.1 Additional terms</p>
          <p>
            Additional terms and conditions may apply to your purchases of goods
            or services from us and to specific portions or features of the
            Website all of which terms are made a part of these Terms by this
            reference.
          </p>
          <p>10.2 Promotions and competitions</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              For certain campaigns, promotions or contests, additional terms
              and conditions may apply. If you want to participate in such a
              campaign, promotion or contest, you need to agree to the relevant
              terms and conditions applicable to that campaign, promotion or
              contest. In case of any inconsistency between such terms and
              conditions and these Terms, those terms and conditions will
              prevail.
            </span>
          </p>
          <p>11. Our rights to the Website</p>
          <p>11.1 Our rights to the Website</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We reserve the right to do any of the following, at any time,
              without notice:&nbsp;
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                to modify, suspend or terminate operation of or access to the
                Website, or any portion of the Website, for any reason;&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                to modify or change the Website, or any portion of the Website,
                and any applicable policies or terms including these Terms;
                and&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                to interrupt the operation of the Website, or any portion of the
                Website, as necessary to perform routine or non-routine
                maintenance, error correction, or other changes.
              </span>
            </li>
          </ul>
          <p>11.2 Access to the Website</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We reserve the right to restrict, suspend or terminate without
              notice your access to the Website, any Content, or any feature of
              the Website at any time without notice and we will not be
              responsible for any loss, cost, damage or liability that may arise
              as a result.
            </span>
          </p>
          <p>11.3 No support</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We reserve the right to restrict, suspend or terminate without
              notice your access to the Website, any Content, or any feature of
              the Website at any time without notice and we will not be
              responsible for any loss, cost, damage or liability that may arise
              as a result.
            </span>
          </p>
          <p>12. Severability</p>
          <p>
            In the event that any of the provisions contained herein shall be
            deemed invalid or unenforceable, then the remaining provisions shall
            be construed as if such invalid or unenforceable provisions were not
            contained herein and will not affect the validity of the remaining
            provisions.
          </p>
          <p>13. Governing law and jurisdiction</p>
          <p>13.1 Governing law</p>
          <p>
            Your use of the Website and these Terms are governed by the laws of
            the State of New South Wales, Australia.
          </p>
          <p>13.2 Dispute resolution</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You submit to the non-exclusive jurisdiction of the courts
              exercising jurisdiction in the State of New South Wales,
              Australia.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
        </Box>
      </Box>
    </Box>
  );
}
