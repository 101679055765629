import { Box } from "@mui/material";

export default function Eula() {
  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 270px)",
        width: "100%",
        background: "#EDE6DC",
      }}
    >
      <Box maxWidth={1324} sx={{ margin: "auto", width: "100%" }}>
        <Box py={2} px={{ xs: "20px", md: 0 }}>
          <h1>MUSE App – End User Licence Agreement</h1>
          <p>1. Introduction</p>
          <p>1.1 Welcome</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Welcome to Muse! We provide an AI-enabled luxury goods resale and
              social commerce platform which you can access via our invite-only
              mobile application (the&nbsp;
            </span>
            <strong style={{ backgroundColor: "initial" }}>Service</strong>
            <span style={{ backgroundColor: "initial" }}>).</span>
          </p>
          <p>1.2 Muse Technology</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The Service is owned and operated by Muse Technology Pty Ltd (ACN
              649 320 178) (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Muse</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). In this End User License Agreement (this&nbsp;
            </span>
            <strong style={{ backgroundColor: "initial" }}>Agreement</strong>
            <span style={{ backgroundColor: "initial" }}>
              ), ‘us’, ‘we’ and ‘our’ means Muse and any of its related bodies
              corporate.
            </span>
          </p>
          <p>2. Acceptance</p>
          <p>2.1 Acceptance</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Please read this Agreement carefully. Understanding its terms is
              important because, by downloading the mobile application
              (the&nbsp;
            </span>
            <strong style={{ backgroundColor: "initial" }}>App</strong>
            <span style={{ backgroundColor: "initial" }}>
              ), applying to become a user, creating a profile or otherwise
              using the Service in any manner, you’re agreeing to this
              Agreement.
            </span>
          </p>
          <p>2.2 Corporate users</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              If you are a corporate user then the individual accepting this
              Agreement is doing so on behalf of you and that individual
              represents and warrants that:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                they have full legal authority to bind you;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                they have read and understood this Agreement; and
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                they agree to the terms of this Agreement on behalf of you.
              </span>
            </li>
          </ul>
          <p>2.3 Non-acceptance</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              If you don’t agree to the terms and conditions contained in this
              Agreement, then please do not use the Service.
            </span>
          </p>
          <p>3. Onboarding</p>
          <p>3.1 Onboarding</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Once your invitation has been verified, you will be invited to
              register with us and set up an account and user profile including
              selecting a username and password.&nbsp;
            </span>
          </p>
          <p>3.2 Alternative sign-in</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Alternatively, you can log in via approved third-party services
              including via Facebook or Google. If so, you give us permission to
              access and use your information from that service in order to
              verify your account and to hold that data including log-in tokens
              for that third-party service in accordance with our privacy
              policy.&nbsp;
            </span>
          </p>
          <p>3.3 Additional requirements for buyers and sellers</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Users who want to use the Service to buy and [re/sell] their
              luxury goods (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Items</strong>
            <span style={{ backgroundColor: "initial" }}>
              ) need to validate their submitted personal details with proof of
              identification.&nbsp;
            </span>
          </p>
          <p>4. Licence grant</p>
          <p>4.1 Licence</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Subject to the completion of the terms in clause 3 above and your
              continued compliance with this Agreement [and in consideration for
              your payment of the Fees] we hereby grant to you a [personal],
              revocable, non-exclusive and non-transferable license to use the
              Services. The provision of this licence is at all times subject to
              the terms and conditions of this Agreement.
            </span>
          </p>
          <p>4.2 Limited access</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The Website is made available on a limited access basis, and no
              ownership right is conveyed to&nbsp;you&nbsp;through your use or
              access of the Website.&nbsp;
            </span>
          </p>
          <p>5. Use of the Website</p>
          <p>5.1 Your responsibilities</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Whilst you use the&nbsp;Website, you agree that you will:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                make sure the email address associated with your account remains
                active and checked by you on a regular basis;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                comply with your obligations under this Agreement;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                be responsible for securing your account including user profile
                and passwords;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                be responsible for all usage of your account with or without
                your knowledge or consent;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                have a valid account with either the Apple App Store or Google
                Play (the&nbsp;
              </span>
              <strong style={{ backgroundColor: "initial" }}>Store</strong>
              <span style={{ backgroundColor: "initial" }}>);</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                be responsible for obtaining and maintaining any equipment and
                ancillary services needed to connect to, access or otherwise use
                the Service including hardware, software, networking etc;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                comply with all Federal and State laws, rules and regulations
                related to or connected with your use of the Service;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                ensure the accuracy, quality and legality of your data; and
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                use all reasonable efforts to prevent unauthorised access to or
                use of the Service and to promptly notify us if you become aware
                of any such unauthorised access to or use of the Service.
              </span>
            </li>
          </ul>
          <p>5.2 Age requirements</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You&nbsp;must be&nbsp;at least 13 years of age&nbsp;and capable in
              your country of residence of entering into a legally binding
              agreement to use the Service.&nbsp;
            </span>
          </p>
          <p>6. Content</p>
          <p>6.1 Our content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Unless otherwise indicated, we own, control or license all rights,
              title and interest (including copyright, designs, patents,
              trademarks and other intellectual property rights) in the Service
              and in all of the material (including all text, graphics,
              photographs, artwork, visual interfaces, user interfaces, logos,
              audio, software and computer code made available through the
              Service (collectively,&nbsp;
            </span>
            <strong style={{ backgroundColor: "initial" }}>Content</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). Content includes, but is not limited to, the design, structure,
              selection, coordination, expression, “look and feel” and
              arrangement of such Content, contained in the Service.
            </span>
          </p>
          <p>6.2 Your Content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Some of our services give you the opportunity to make your own
              Content publicly available through the Service. You remain solely
              responsible for your Content.&nbsp;Specifically, when you share,
              post or upload Content that is covered by intellectual property
              rights on or in connection with the Service, you grant us a
              non-exclusive, transferable, sub-licensable, royalty-free and
              worldwide licence to host, use, distribute, modify, run, copy,
              publicly perform or display, translate and create derivative works
              of your Content. This licence will end when your Content is
              deleted from our systems.&nbsp;
            </span>
          </p>
          <p>6.3 Your rights in your Content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You&nbsp;represent and warrant that you have, or have obtained,
              all rights, licenses, consents, permissions, power and/or
              authority necessary to grant the rights granted herein for any
              Content that you create, submit, post, promote, or display on or
              through the Service. You represent and warrant that such Content
              does not contain material subject to copyright, trademark,
              publicity rights, or other intellectual property rights, unless
              you have necessary permission or are otherwise legally entitled to
              post the material and to grant us the license described above, and
              that the Content does not violate any laws.&nbsp;Where necessary,
              we may need to remove user-generated Content from the Service.
            </span>
          </p>
          <p>6.4 Music in your Content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Subject to clause [6.3] above, us of music in your Content for
              commercial or non-personal purposes in particular is prohibited
              unless you have obtained appropriate licences. If you post Content
              that contains music owned by someone else, your content may be
              blocked or removed at the request of the applicable rights owner.
            </span>
          </p>
          <p>6.5 Paid promotion</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              If your Content has a paid product placement, endorsement or other
              commercial relationship, you need to clearly mark it as such in
              your Content. Please note, beyond the terms of this Agreement, you
              may have more obligations under applicable laws in your
              jurisdiction. We reserve the right to take action against your
              Content or account if you contravene this clause.&nbsp;
            </span>
          </p>
          <p>6.6 Live Content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              All pre-recorded Content which is broadcast streamed to the
              Service as part of live Content must be clearly marked as
              pre-recorded. Failure to do so may result in that Content being
              blocked or removed. Live Content is not to include broadcasting
              looping videos, still images or poll-only videos.&nbsp;&nbsp;
            </span>
          </p>
          <p>6.7 Misrepresenting Live Content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Where you post Content to the Service that purports to be live,
              you must not mislead other users to think that specific footage:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                is occurring in real time when it is not; or
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                is happening in a particular location when it is not.
              </span>
            </li>
          </ul>
          <p>6.8 Advertising using Content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You hereby grant us permission&nbsp;to use your profile
              information and information about actions that you have taken on
              the Service next to or in connection with ads, offers and other
              sponsored content that we display across the Service, without any
              compensation to you. You acknowledge that this permission survives
              termination of this Agreement.
            </span>
          </p>
          <p>6.9 Third-party Content</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We may integrate Content or applications from third-parties with
              the Service and make some or all of them available to you. The
              views expressed in third-party Content or applications are theirs,
              and don’t necessarily reflect our views. Any Content or
              applications from third-parties are provided through the Service
              on an ‘as-is’ basis and we make no express or implied
              representations or warranties about that Content or those
              applications. Content or applications from third-parties are
              subject to change or discontinuance on the Website without notice
              or entitlement to you of any refund, credit or other form of
              compensation.
            </span>
          </p>
          <p>7. Social commerce</p>
          <p>7.1 Social commerce</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The Service allows you to buy and [re/sell] Items with other
              users. You can sell your Items through the Service by publishing
              pictures you want to sell on the App (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Items</strong>
            <span style={{ backgroundColor: "initial" }}>).&nbsp;</span>
          </p>
          <p>7.2 Risks</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              While we strive to maintain a safe trading environment you accept
              that there are unfortunately sometimes risks when trading online
              and using our sites, including dealing with fraudulent people. You
              agree that you will not hold us responsible for other users’ acts
              or omissions. You acknowledge that the Service is not as an
              auctioneer. Instead, the App is a platform to allow anyone to
              offer, sell, and buy Items at anytime or from anywhere.
            </span>
          </p>
          <p>7.3 Legally binding transaction</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              When you enter into a transaction through the Service, you create
              a legally binding contract with the other user. You must ensure
              that you comply with your obligations to that other member and are
              aware of any laws relevant to you as a buyer or seller. If another
              member breaches any obligation to you, you are responsible for
              enforcing any rights you may have.
            </span>
          </p>
          <p>7.4 Responsibility for transactions</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You alone, and not us, are responsible for ensuring that your
              listing, bidding and selling and any other activities conducted on
              the App are lawful. You must ensure that you comply with all
              applicable laws in Australia and any other applicable country. You
              must also ensure that you strictly comply with this Agreement and
              the policies which form part of this Agreement.&nbsp;
            </span>
          </p>
          <p>7.5 Responsibility for Items</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We do not take ownership of the Items at any time and do not
              transfer legal ownership of Items from the seller to the buyer. We
              have no control and do not give any commitment relating to the
              existence, quality, safety, genuineness or legality of any Item,
              the truth or accuracy of any picture or description of any Item or
              any other Content made available by users, the ability of sellers
              to sell the Items, the ability of buyers to pay for them or that a
              buyer or seller will actually complete a transaction or return the
              Item, and we
            </span>
          </p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              have no liability in this respect.
            </span>
          </p>
          <p>7.6 International sales</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The Service is accessible internationally. Registered buyers and
              sellers can use our App to buy and sell Items. Items available on
              the App may be located overseas and/or sold by international
              sellers relative to the buyer. Buyers and sellers are responsible
              for complying with all laws and regulations applicable to the
              international sale, purchase and shipment of Items, including
              paying any applicable taxes.
            </span>
          </p>
          <p>8. Prohibited Activities and Items</p>
          <p>8.1 Prohibited activity</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Unless expressly stated otherwise in this Agreement, you agree
              that you will not do any of the following:&nbsp;
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Create more than one account with the Service unless we
                expressly agree otherwise.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Claim a username or the purpose of reselling it or otherwise
                engage in name squatting.&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Services if you are not able to form legally binding
                contracts or&nbsp;are under the age of 13.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Permit any other person to use the Service with your account
                unless you are a corporate user and that person is authorised by
                you.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service to store or transmit infringing, libelous or
                otherwise unlawful or tortious material or inappropriate
                communications or content (including, but not limited to,
                defamatory, racist, obscene, threatening or pornographic
                material), or to store or transmit material in violation of
                third-party privacy rights.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Using the Service for any wrongful activities including, but not
                limited to, using the service to stalk, harass, threaten or
                violate the privacy of others.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Submit or contribute any information/commentary about another
                user without their consent or post private/confidential
                information via the Service including their details.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service to store or transmit malicious code including
                code, files, scripts, agents or programs intended to do harm,
                including, for example, viruses, worms, time bombs and Trojan
                horses.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Interfere with or disrupt the integrity or performance of the
                Service including, but not limited to, causing or encouraging
                inaccurate measurements of genuine user engagement with
                particular Content or otherwise manipulate metrics in any
                manner.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Take any action that imposes an unreasonable or
                disproportionately large load on the infrastructure of the
                Service or our systems or networks, or any systems or networks
                connected to the App or to the Service.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Attempt to gain unauthorised access to the Service or its
                related systems or networks.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service if we have suspended or banned you from using
                it.&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Permit either direct or indirect access to or use of the Service
                that attempts to circumvent any contractual usage limits.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Offer to sell or to buy any&nbsp;Prohibited Items (as that term
                is defined below at clause [8.2]).
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service to distribute unsolicited promotional or
                commercial Content or other unwanted or mass solicitations.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                If you’re a buyer, fail to deliver payment for Items purchased
                by you, unless the seller has materially changed the Item’s
                description after you bid or a clear typographical error is
                made.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                If you’re a seller, fail to deliver Items purchased from you,
                unless the buyer fails to meet the posted terms.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Manipulate the price of any Item or interfere with other users’
                listings on the Service including, but not limited to, unfairly
                or unlawfully&nbsp;interfering or manipulating the ratings
                system or user feedback system.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Buy or sell Items using alternative payment methods beyond those
                permitted or accepted by the Service including, but not limited
                to, making direct arrangements with a buyer or seller to
                circumvent the Service.&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service for&nbsp;money&nbsp;laundering, terrorist
                financing, or other illicit finance.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service from a country sanctioned by the government of
                the country in which you reside to facilitate
                transactions&nbsp;involving&nbsp;individuals sanctioned by that
                government or located in sanctioned countries.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service to carry out any financial activities subject to
                registration or licensing, including but not limited to
                creating, selling, or buying securities, commodities, options,
                or debt instruments.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service to create, sell, or buy non-fungible tokens (
              </span>
              <strong style={{ backgroundColor: "initial" }}>NFT</strong>
              <span style={{ backgroundColor: "initial" }}>
                ) or other Items that give owners’ rights to participate in an
                initial coin offering or any share offering, or that are
                redeemable for shares, commodities, or other financial
                instruments.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Use the Service to drop ship.&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Copy or collect any Content on the Service including using
                “deep-link”, “page-scrape”, “robot”, “spider” or other automatic
                device, program, algorithm or methodology, or any similar or
                equivalent manual process, to access, acquire, copy or monitor
                any portion of the Service or any Content, or in any way
                reproduce or circumvent the navigational structure or
                presentation of the Service or any Content, to obtain or attempt
                to obtain any materials, documents or information through any
                means not purposely made available through the Service.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Modify or create derivative works based on the Service or any
                part, feature, function or user interface thereof.&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Disassemble, reverse engineer, or decompile any part of the
                Service or access it to;&nbsp;
              </span>
            </li>
            <li className="ql-indent-1">
              <span style={{ backgroundColor: "initial" }}>
                build a competitive product or service;
              </span>
            </li>
            <li className="ql-indent-1">
              <span style={{ backgroundColor: "initial" }}>
                build a product or service using similar ideas, features,
                functions or graphics of the Service;
              </span>
            </li>
            <li className="ql-indent-1">
              <span style={{ backgroundColor: "initial" }}>
                copy any ideas, features, functions or graphics of the
                Service.&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Determine whether any ideas, features, functions or graphics of
                the Service are within the scope of any patent.
              </span>
            </li>
          </ul>
          <p>8.2 Prohibited Items</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Whilst we reserve the right to decide at any time what Items are
              or aren’t allowed on the Service, the following types of items are
              considered&nbsp;
            </span>
            <strong style={{ backgroundColor: "initial" }}>
              Prohibited Items&nbsp;
            </strong>
            <span style={{ backgroundColor: "initial" }}>
              for the purposes of this Agreement:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Adult only material and nudity
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Advertisements</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Alcohol</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Animals and wildlife products
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Counterfeit currency and stamps
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Counterfeit items, replicas and unauthorised copies
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Credit cards</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Data banks, mailing lists and personal information
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Illicit drugs and drug paraphernalia
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Drop-shipping</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Food and edible items
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Firearms, weapons and military items
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Fireworks and&nbsp;kit for their implementation
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Goods from sanctioned countries
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Government, transit and shipping-related items, government
                documents, IDs and licenses
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Graphic and violent imagery
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Hate speech and discriminatory content
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Hazardous materials
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Human remains, human organs and body parts
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Lock-picking and locksmithing devices
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Lotteries and giveaways
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                All medical devices (prescription and over-the counter medical
                devices)
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Perfumes and cosmetics
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Plants and seeds
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Real estate</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Recalled items</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Services</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Software (not including digital artworks)
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                State or government owned property
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Shares and other marketable securities
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Stolen property and property with removed serial numbers
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Technology and electronics
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Tickets</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>Tobacco</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Vouchers,&nbsp;gift cards, digital and non-material goods.
              </span>
            </li>
          </ul>
          <p>9. Payments</p>
          <p>9.1 Payment Services</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              [Insert&nbsp;payment terms]
            </span>
          </p>
          <p>10. Sellers commission</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              If you are a seller, you agree to pay us a commission on the total
              transaction amount received by you (including VAT and any other
              applicable taxes, if any, and shipping costs) in respect of the
              sale of any of your Items through the Service (the&nbsp;
            </span>
            <strong style={{ backgroundColor: "initial" }}>Commission</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). Further details of the Commission can be found here
              [insert].&nbsp;
            </span>
          </p>
          <p>11. Taxes on purchases</p>
          <p>11.1 Payment of taxes</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Buyers and sellers are generally responsible for any goods and
              services tax (
            </span>
            <strong style={{ backgroundColor: "initial" }}>GST</strong>
            <span style={{ backgroundColor: "initial" }}>
              ), sales tax, value-added tax (
            </span>
            <strong style={{ backgroundColor: "initial" }}>VAT</strong>
            <span style={{ backgroundColor: "initial" }}>
              ) or other taxes that apply to Items bought through the Service.
              If you have any questions or require more information about your
              tax obligations, please seek independent tax advice.
            </span>
          </p>
          <p>11.2 International taxes</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              In any jurisdiction where we have an obligation to collect tax
              (including GST or VAT), duties, customs or other international
              charges on consumer purchases, we:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                may add the applicable tax to the item price displayed to the
                buyer;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                will display the tax amount at checkout once the buyer’s order
                and delivery address are confirmed, and this will be included in
                the order total paid by the buyer;&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                will collect this amount via any means available to us and remit
                the tax to the relevant authority.
              </span>
            </li>
          </ul>
          <p>12. Shipping</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The seller of an Item is responsible for shipment of the Item to
              the successful buyer.&nbsp;
            </span>
          </p>
          <p>13. Infringement Notice</p>
          <p>13.1 Infringement Notice</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Any user may contact us by sending us a written notice if any
              Content or Item available through the Service infringes their
              rights or fails to comply with this Agreement (
            </span>
            <strong style={{ backgroundColor: "initial" }}>
              Infringement Notice
            </strong>
            <span style={{ backgroundColor: "initial" }}>
              ). An Infringement Notice must specify contact details and the
              specifics of the infringement or contravention with sufficient
              detail and evidence.
            </span>
          </p>
          <p>13.2 Our response to an Infringement Notice</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              An Infringement Notice can be sent to us at the details set out in
              [clause&nbsp;27] below. When we receive
              an&nbsp;Infringement&nbsp;Notice, we will aim to respond to it
              within a reasonable period of time. We will take the action which
              we believe is appropriate depending on the nature of the
              Infringement Notice.
            </span>
          </p>
          <p>14. Links to other sites</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The Service may contain links to other independent third-party
              websites (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Linked Sites</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). These Linked Sites are provided solely as a convenience to you.
              Such Linked Sites are not under our control, and we are not
              responsible for and do not endorse the content of such Linked
              Sites,&nbsp;including&nbsp;any information or materials contained
              on such Linked Sites. You will need to make your own independent
              judgment regarding your interaction with these Linked Sites.
            </span>
          </p>
          <p>15. Charitable Donations</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              If you make a donation to a charity through the Service, you
              acknowledge that you make this&nbsp;donation&nbsp;as a charitable
              contribution and that you are not receiving any goods or services
              in return. As the tax laws vary by State and by Country, please
              seek independent professional tax advice regarding the
              deductibility of this donation.
            </span>
          </p>
          <p>16. Disclaimer</p>
          <p>16.1 No promise</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We provide the Service to you using a commercially reasonable
              level of care. However, there are certain things that we don’t
              promise about the Service.
            </span>
          </p>
          <p>16.2 No warranties</p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, WE DO NOT MAKE
                ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
                OTHERWISE, AND WE SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES,
                INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT
                PERMITTED BY APPLICABLE LAW. THE SERVICE, INCLUDING THE APP, IS
                PROVIDED ‘AS IS’ AND ‘AS AVAILABLE’.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                WE DO NOT WARRANT THAT THE SERVICE OR THE CONTENT WILL BE
                COMPLETE, ACCURATE OR UP-TO-DATE. ALL INFORMATION PROVIDED
                THROUGH THE SERVICE IS SUBJECT TO CHANGE WITHOUT NOTICE.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                WE DO NOT WARRANT THAT OUR SECURITY PROCEDURES WILL BE
                ERROR-FREE OR THAT YOUR DATA WILL ALWAYS BE SECURE IN
                TRANSMISSION.
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                WE PROVIDE NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, WITH
                RESPECT TO ANY PRODUCTS OR SERVICES SOLD ON OR THROUGH THE
                SERVICE BY USERS.
              </span>
            </li>
          </ul>
          <p>16.3 Your statutory guarantees</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You have statutory guarantees under the Australian Consumer Law
              and nothing in this Agreement is intended to affect such rights.
            </span>
          </p>
          <p>17. Limitation of liability</p>
          <p>17.1 No liability</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              To the maximum extent permitted by law, including the Australian
              Consumer Law, in no event shall&nbsp;we&nbsp;be liable for any
              direct and indirect loss, damage or expense (irrespective of the
              manner in which it occurs) which may be suffered due to your use
              of the Service and/or the information or materials contained on
              it, or as a result of the inaccessibility of the Service and/or
              the fact that certain information or materials contained on it are
              incorrect, incomplete or not up-to-date.
            </span>
          </p>
          <p>17.2 Remedy</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              To the extent that we are permitted to limit the remedies
              available under this Agreement, we expressly limit our liability
              for breach of a non-excludable statutory guarantee to the
              following remedies:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                supply of the services again; or
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                the payment of the costs of having the services supplied again.
              </span>
            </li>
          </ul>
          <p>17.3 NFTs</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The&nbsp;value of an NFTs is subjective. Prices of NFTs are
              subject to volatility and fluctuations in the&nbsp;price&nbsp;of
              cryptocurrency can also materially and adversely affect NFT
              prices. You acknowledge that you fully understand this
              subjectivity and volatility and that you may lose money.
            </span>
          </p>
          <p>18. Indemnity</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You&nbsp;agree to indemnify and hold us and our officers,
              directors, employees, agents, subsidiaries and affiliates,
              harmless from any demands, loss, liability, claims or expenses
              (including legal fees), made against us by any third party due to
              or arising out of or in connection with your use of the Service.
            </span>
          </p>
          <p>19. Feedback</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Occasionally, you may provide us with feedback on our Website (
            </span>
            <strong style={{ backgroundColor: "initial" }}>Feedback</strong>
            <span style={{ backgroundColor: "initial" }}>
              ). We may in connection with any of our products or services
              freely use, copy, disclose, license, distribute and exploit any
              Feedback in any manner without any obligation, royalty or
              restriction based on intellectual property rights or otherwise.
              Nothing in these Terms limit our right to independently use,
              develop, evaluate, or market products or services, whether
              incorporating Feedback or otherwise.
            </span>
          </p>
          <p>20. Termination</p>
          <p>20.1 Term of Agreement</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              This Agreement is effective from the date of your acceptance as
              per clause [2.1] and continues until the deactivation of your
              account.&nbsp;
            </span>
          </p>
          <p>20.2 Deletion of your account</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You may delete your account at any timeOther than via the App, you
              may request deactivation of your account by contacting us from the
              email address linked to your account provided that:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                there are no outstanding Items of yours listed on the Service;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                all outstanding matters concerning your account (such as
                suspension or restriction) have been resolved; and
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                [there are no outstanding Fees owing on the account].
              </span>
            </li>
          </ul>
          <p>20.3 Our rights with respect to your account</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We may modify, suspend or terminate your access to or use of the
              Service anytime for any reason. Particularly, if you contravene
              clause [8] of this Agreement then it constitutes a serious breach
              for which we may immediately and without further notice to you:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                temporarily/permanently withdraw your right to use the Service;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                remove your Content;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                issue written warnings or take legal action; or&nbsp;
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                disclose relevant information to law enforcement.
              </span>
            </li>
          </ul>
          <p>20.4 Fees on termination</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Upon the termination of this Agreement, you will pay any unpaid
              Fees incurred on or before the date of termination. For any
              prepaid Fees, you will receive a pro-rata refund of those Fees for
              the unused portion.
            </span>
          </p>
          <p>20.5 Content upon termination</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Upon termination of this Agreement you must cease accessing or
              using the Service. If you deactivate or end usage of the Service
              then we can delete or modify your Content. You will lose any
              rights you have to your Content with no compensation. Data may
              still persist and appear on the Service even on deactivation
              including where your Content has been re-shared by others and they
              have note deleted it.&nbsp;&nbsp;
            </span>
          </p>
          <p>20.6 Survival</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              The following clauses survive termination of this Agreement:
              Clause 5.2 (Age requirements),&nbsp;
            </span>
          </p>
          <p>21. Disputes</p>
          <p>21.1 Disputes between you and us</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              If a dispute arises between you and us, our goal is to provide you
              with a neutral and cost-effective means of resolving the dispute
              quickly. We strongly encourage you to first contact us via the
              details below at clause [insert]
            </span>
            &nbsp;
            <span style={{ backgroundColor: "initial" }}>
              to seek a resolution.&nbsp;
            </span>
          </p>
          <p>21.2 Reporting disputes between you and other users</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              All purchases or sales made outside the Service is against this
              Agreement and accordingly are not covered by its terms. In the
              event of a dispute between you and other users, we strongly
              encourage you to try and resolve this dispute directly. Failing
              that however, if you’re:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                a buyer and your purchased Item has not arrived, or if what
                you’ve received is materially not as described in the
                transaction or faulty; or
              </span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                a seller and you’ve been sent an unauthorised payment or a buyer
                claims they’ve not received their purchased Item from you,
              </span>
            </li>
          </ul>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              then you can report the issue to us within 90 days and we will
              reply to you with instructions on how to proceed. Please note that
              we can only accept tracking codes as valid proof of delivery and
              the cost of returning the Item cannot be refunded.
            </span>
          </p>
          <p>21.3 Resolving disputes between you and other users</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Where you and the other user cannot agree a resolution, we will
              try to mediate a resolution between you and the other user.&nbsp;
            </span>
          </p>
          <p>21.4 Confidentiality</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              All negotiations pursuant to this clause [21] are to be on a
              confidential basis.
            </span>
          </p>
          <p>22. Privacy</p>
          <p>22.1 Collection</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We&nbsp;collect certain data and information about you in
              connection with your use of the Service and&nbsp;otherwise&nbsp;in
              connection with this Agreement. We collect and use all personal
              information in such data and information in accordance with
              applicable privacy laws and our privacy policy, which you
              acknowledge.
            </span>
          </p>
          <p>22.2 Disclosure</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We may disclose that information to third parties that help us
              deliver the Service (including information technology suppliers,
              communication suppliers and our business partners) or as required
              by law. If you do not provide this information, we may not be able
              to provide all of the functionality of the Service to you.
            </span>
          </p>
          <p>22.3 Consent</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              By providing your personal information to us, you consent to the
              collection, use, storage and disclosure of that personal
              information as described in our privacy policy and this Agreement.
            </span>
          </p>
          <p>23. Other terms and conditions</p>
          <p>23.1 Additional terms</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Additional terms and conditions may apply to your purchases of
              goods or services from us and to specific portions or features of
              the Service all of which terms are made a part of this Agreement
              by this reference.
            </span>
          </p>
          <p>23.2 Promotions and competitions</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              For certain campaigns, promotions or contests, additional terms
              and conditions may apply. If you want to participate in such a
              campaign, promotion or contest, you need to agree to the relevant
              terms and conditions applicable to that campaign, promotion or
              contest. In case of any inconsistency between such terms and
              conditions and this Agreement, those terms and conditions will
              prevail.
            </span>
          </p>
          <p>24. Changes to the Service and this Agreement</p>
          <p>24.1 Our right to change the Services</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You&nbsp;acknowledge that the Service is
              on-line,&nbsp;subscription-based&nbsp;product, and that in order
              to provide&nbsp;improved&nbsp;customer experience we may make
              changes including&nbsp;modifying, suspending or terminating
              operation of or access to the Service, and we may update the
              applicable documentation accordingly.
            </span>
          </p>
          <p>24.2 Our right to amend this Agreement</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We may revise this Agreement from time to time in order to
              incorporate new features being added to the Service, changes in
              the law or our business model and any changes will take effect
              after 3 days of any notification email sent to you notifying you
              of any changes, or at the time the revised Agreement is posted
              (whichever is the earlier) and your continued use of the Service
              shall constitute acceptance of such revised Agreement. Normally,
              we will try to give you some warning before the new terms become
              effective. However, sometimes changes will need to be made
              immediately and if this happens we will not give you any notice.
            </span>
          </p>
          <p>24.3 Access to the Services</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              In addition to any other provision in this Agreement, we reserve
              the right to restrict, suspend or terminate without notice your
              access to the Service, any Content, or any feature of the Website
              at any time without notice and we will not be responsible for any
              loss, cost, damage or liability that may arise as a result.
            </span>
          </p>
          <p>24.4 No support</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              We’re not obligated under this Agreement to provide any support
              services to you for the Services.
            </span>
          </p>
          <p>24.5 US export laws</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You agree that you will not export or re-export, directly or
              indirectly, the Service, and/or other information or materials
              provided by us, to any country for which the United States or any
              other&nbsp;relevant&nbsp;jurisdiction requires any export licence
              or other governmental approval at the time of export without first
              obtaining such licence or approval. You are responsible for and
              hereby agree to comply at your sole expense with all applicable
              United States export laws and regulations.
            </span>
          </p>
          <p>25. General provisions</p>
          <p>25.1 Entire agreement</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Unless specifically referred to herein, this Agreement is the
              entire agreement between you and us relating to the Service and
              supersedes any prior or contemporaneous oral or written
              communication, proposals and representations between you and us
              with respect to the Service or any other subject matter covered by
              this Agreement.
            </span>
          </p>
          <p>25.2 Relationship between you and us</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              This Agreement does not create or evidence a partnership, joint
              venture or any other fiduciary relationship between you and us.
              You and us are independent, and each has sole authority and
              control of the manner of, and is responsible for, its performance
              of its obligations under this Agreement.
            </span>
          </p>
          <p>25.3 Waiver and severability</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Our failure to enforce a provision is not a waiver of its right to
              do so later. If a provision is found to be unenforceable, the
              remaining provisions of this Agreement will remain in full effect
              and an enforceable term will be substituted reflecting our intent
              as closely as possible.
            </span>
          </p>
          <p>25.4 Assignment</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You may not assign any of your rights under this Agreement, and
              any such attempt will be void. We may assign our rights to any of
              our affiliates or subsidiaries, or to any successor in the
              interest of any business associated with the Service.
            </span>
          </p>
          <p>26. Governing law and jurisdiction</p>
          <p>26.1 Governing law</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              Your use of the Service is governed by the laws of the State of
              New South Wales, Australia.
            </span>
          </p>
          <p>26.2 Dispute resolution</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              You submit to the non-exclusive jurisdiction of the courts
              exercising jurisdiction in the State of New South Wales, Australia
            </span>
          </p>
          <p>27. Contact</p>
          <p>
            <span style={{ backgroundColor: "initial" }}>
              If you need to contact us in relation to this Agreement or any
              other document mentioned here, please contact us via the
              following:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                &nbsp;Using the ‘Contact us’ section on our website:&nbsp;
              </span>
              <a
                href="https://www.jointhemuse.com/"
                target="_blank"
                style={{ color: "rgb(48, 48, 48)" }}
              >
                www.jointhemuse.com
              </a>
              <span style={{ backgroundColor: "initial" }}>.</span>
            </li>
            <li>
              <span style={{ backgroundColor: "initial" }}>
                Email us:&nbsp;
              </span>
              <a
                href="about:blank"
                target="_blank"
                style={{ color: "rgb(48, 48, 48)" }}
              >
                info@
              </a>
              <span style={{ backgroundColor: "initial" }}>
                jointhemuse.com.&nbsp;&nbsp;
              </span>
            </li>
          </ul>
          <p>
            <br />
          </p>
        </Box>
      </Box>
    </Box>
  );
}
